import Vimeo from '@u-wave/react-vimeo';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ColorRing } from 'react-loader-spinner';
import ReactPlayer from 'react-player';
import { Link, useParams } from 'react-router-dom';
import WebFont from 'webfontloader';
import { images } from './../../Constants';
import axios from './../../axios';
import { Footer, ImageBlog, ImageBlogPost, NavBar, SharePost } from './../index';
import './BlogPost.scss';

const BlogPost = () => {
    const [fontLoaded, setFontLoaded] = useState(false);
    const [gotData, setGotData] = useState(false);
    const [name, setName] = useState(useParams().name);
    const [webUrl, setWebUrl] = useState('');
    const [data, setData] = useState([]);
    const [nameData, setNameData] = useState('');
    const [emailData, setEmailData] = useState('');
    const [commentData, setCommentData] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emptyInput, setEmptyInput] = useState(false);
    const [tooLongNickname, setTooLongNickname] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [canLike, setCanLike] = useState(true); //To not overload requests
    const textareaRef = useRef();

  useEffect(() => {
    axios.get(`/getPostItemData?name=${name}`).then((response) => {
      if(response.data.status === 'ok') {
        document.title = response.data.title;
        setWebUrl(response.data.url);
        setData(response.data);
        const comments = response.data.comments;
        const updatedComments = comments.map(comment => ({...comment, liked: false}));
        const updatedData = {...response.data, comments: updatedComments};
        setData(updatedData);
        setGotData(true);
      }
    });

    let storedToken = localStorage.getItem('adminToken');

    axios.get(`verify?token=${storedToken}`).then((response) => {
        if(response.data.status === "authorized") {
            setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  let parseDate = () => {
    if(data.created_at !== '' && data.created_at) {
        const date = data.created_at;
        const transformedDate = date.split('T')[0];
        const partsDate = transformedDate.split('-');
        const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
        return reversedDate;  
    } else {
        return data.created_at
    }
  }

  let parseCommentDate = (e) => {
    if(e) {
      const date = e;
      const transformedDate = date.split('T')[0];
      const partsDate = transformedDate.split('-');
      const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
      return reversedDate;  
    } else {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Miesiące są numerowane od 0 do 11
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    }
  }

  let sendMessage = () => {
    if(nameData !== '' && emailData !== '' && commentData !== '') {
      if(nameData.length < 17) {
        let send = [nameData, emailData, commentData, data.postId];
        axios.post('/postComment', send).then((response) => {
          if(response.data.status === 'ok') {
            const newComment = {id: response.data.data.id, post_id: response.data.data.post_id, author: response.data.data.author, content: response.data.data.content, likes: response.data.data.likes, liked: false, created_at: response.data.data.createdAt};
            const updatedData = {
              ...data, comments: [newComment, ...data.comments]
            };
            setData(updatedData);
            setCommentData('');
            setEmailData('');
            setNameData('');
            textareaRef.current.style.height = '22px';
          } else if(response.data.status === 'Email Invalid') {
            setEmailInvalid(true);
            setTimeout(() => {
              setEmailInvalid(false);
            }, 3000)
          }
        })
      } else {
        setTooLongNickname(true);
        setTimeout(() => {
          setTooLongNickname(false);
        }, 3000)
      }
    } else {
      setEmptyInput(true);
      setTimeout(() => {
        setEmptyInput(false);
      }, 3000)
    }
  }

  let handleLikeComment = (e) => {
    //e[0] - likeCount
    //e[1] - isLiked
    //e[2] - id
    if(canLike) {
      setCanLike(false);
      axios.post('/likeComment', e).then((response) => {

      })

      setData((prevData) => {
        const updatedComments = prevData.comments.map((comment) => comment.id === e[2] ? (comment.liked ? {...comment, liked: false} : {...comment, liked: true}) : comment);
        return {...prevData, comments: updatedComments}
      })

      setTimeout(() => {
        setCanLike(true);
      }, 1000)
    }
  }

  let deleteComment = (e) => {
    const id = e;
    axios.post('/deleteComment', {id}).then((response) => {
      if(response.data.status === 'ok') {
        setData((prevData) => {
          const updatedComments = prevData.comments.filter((comment) => comment.id != id);
          return {...prevData, comments: updatedComments}
        })
      }
    })
  }

  let handleChangePage = () => {
    window.location.href = `/blog/post/${data.latestPost.title}`;
  }

  let handleTextareaChange = (e) => {
    setCommentData(e.target.value);
    const textar = textareaRef.current;
    if(textar) {
      textar.style.height = 'auto';
      textar.style.height = textar.scrollHeight + 'px';
    }
  }

  let isYoutube = () => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    if(pattern.test(data.video_url)) {
        return true;
    } else return false;
  }

  let isVimeo = () => {
    const pattern = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    if(pattern.test(data.video_url)) {
        return true;
    } else return false;
  }

  let getYoutubeEmbed = () => {
    const lastIndex = data.video_url.lastIndexOf('/');
    let lastPart = '';
    if(lastIndex > -1 && lastIndex < data.video_url.length - 1) {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 1];
    } else {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 2];
    }
    const split = lastPart.split('v=');
    return split.pop();
  }

  let getVimeoId = () => {
    const lastIndex = data.video_url.lastIndexOf('/');
    let lastPart = '';
    if (lastIndex > -1 && lastIndex < data.video_url.length - 1) {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 1];
    } else {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 2];
    }
    
    const indexOfQuestionMark = lastPart.indexOf('?');
    const videoId = indexOfQuestionMark !== -1 ? lastPart.substring(0, indexOfQuestionMark) : lastPart;
    
    const split = videoId.split('v=');
    return split.pop();
  }

  return (
    <div>
      {fontLoaded && gotData && webUrl !== '' ? (
        <div className='post-item__container'>
          <Helmet>
            <meta property='og:type' content="article" />
            <meta property="og:url" content={`${webUrl}/blog/post/${name}`} />
            <meta property='og:title' content={data.title} />
            <meta property='og:description' content={data.data[data.video_url ? 0 : 1].content.substring(0, 100)} />
            <meta property='og:image' content={data.mainImage} />

            <meta name="twitter:title" content={data.title} />
            <meta name="twitter:description" content={data.data[data.video_url ? 0 : 1].content.substring(0, 100)} />
            <meta name="twitter:image" content={data.mainImage} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>

          <NavBar/>
          <motion.div className='post-item' anitial={{opacity: 0}} animate={{opacity: 1}} transition={{ ease: 'easeOut', duration: 0.5}}>
            <Link to={`/blog/${data.postNumber}`} className='post-item__go-back-wrapper'><button className='post-item__go-back'><img src={images.arrowLeft} alt='Arrow'/><span>GO BACK</span></button></Link>
            <div className='post-item__head-wrapper'>
              <div className='post-item__date'>{parseDate(data.created_at)}</div>
              <div className='post-item__title'>{data.title}</div>
            </div>
            <div className='post-item__content'>
              {data.video_url ? (
                <div>
                  {isYoutube() ? (
                    <div className='post-item__video-player youtube'>
                        <iframe
                          src={`https://youtube.com/embed/${getYoutubeEmbed()}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder='0'
                          title={data.title}
                        />
                    </div>
                  ) : isVimeo() ? (
                    <div className='post-item__video-player vimeo'><Vimeo video={getVimeoId()}/></div>
                  ) : (
                    <div className='post-item__video-player facebook'><ReactPlayer width='fit-content' height='100%' url={data.video_url}/></div>
                  )}
                  <div className='post-item__text'>
                    {(data.data[0].content).split('\n').map((textItem, index) => (
                      <p key={index}>{textItem}</p>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  {data.data.map((item, index) => {
                    if(index % 2 == 0) {
                      return(
                        <div key={index} className='post-item__image-wrapper'><ImageBlogPost src={item.image_url} alt={`Image of ${item.title}`}/></div>
                      )
                    } else {
                      return (
                      <div key={index} className='post-item__text'>
                        {
                          item.content.split('\n').map((textItem, indexText) => (
                            <p key={indexText}>{textItem}</p>
                          ))
                        }
                      </div>
                      )
                    }
                  })}
                </div>
              )}
            </div>
            <div className='post-item__share-wrapper'>
              <SharePost title={data.title} short_desc={data.video_url !== "" ? data.data[0].content : data.data[1].content} image={data.mainImage} />
            </div>
            <div className='post-item__comments'>
              <span className='post-item__comments-head'>Comments</span>
              <div className='post-item__comments-rowdata'>
                <div>
                  <label>Your name</label>
                  <input type='text' value={nameData} placeholder='Name' onChange={(e) => setNameData(e.target.value)}/>
                </div>
                <div>
                  <div><label>Your email address</label><div className='post-item__comments-question'><img src={images.circleQuestion}/></div></div>
                  <input type='email' value={emailData} placeholder='Email Address' onChange={(e) => setEmailData(e.target.value)}/>
                </div>
              </div>
              <div className='post-item__comments-messagebox'>
                <textarea 
                  placeholder='Write Comment' 
                  value={commentData} 
                  onChange={handleTextareaChange}
                  ref={textareaRef}
                  rows='1'
                  style={{height: '22px'}}
                ></textarea>
                <button onClick={sendMessage}><img src={images.paperPlane}/></button>
              </div>
              <AnimatePresence>
              {emailInvalid && (<motion.div className='post-item__comments-modal' initial={{opacity: 0, bottom: '-16px'}} animate={{opacity: 1, bottom: '8px'}} exit={{opacity: 0, bottom: '-16px'}}>Email Invalid</motion.div>)}
              {emptyInput && (<motion.div className='post-item__comments-modal' initial={{opacity: 0, bottom: '-16px'}} animate={{opacity: 1, bottom: '8px'}} exit={{opacity: 0, bottom: '-16px'}}>Enter All Data</motion.div>)}
              {tooLongNickname && (<motion.div className='post-item__comments-modal' initial={{opacity: 0, bottom: '-16px'}} animate={{opacity: 1, bottom: '8px'}} exit={{opacity: 0, bottom: '-16px'}}>Your Name is too long (max 16 characters)</motion.div>)}
              </AnimatePresence>
              {data.comments.length > 0 ? (
                <div className='post-item__comments-list'>
                  {data.comments.map((comment, index) => (
                    <div key={index} className='post-item__comments-item'>
                      <div className='post-item__comments-content'>
                        {comment.content.split('\n').map((line, index) => (
                          <span key={index}>{line}</span>
                        ))}
                      </div>
                      <div className='post-item__comments-info'>
                        <div>
                          <b>@{comment.author}</b>
                          <span>{parseCommentDate(comment.createdAt)}</span>
                        </div>
                        <div className='post-item__comments-like-container'>
                          {isAdmin && (<button onClick={() => deleteComment(comment.id)}>DELETE</button>)}
                          <span style={comment.liked ? {fontWeight: 'bold'} : {}}>{comment.liked ? comment.likes + 1 : comment.likes}</span>
                          <button className='post-item__comments-like-button' onClick={() => handleLikeComment([comment.likes, comment.liked, comment.id])}>
                          <svg width="32" height="32" viewBox="0 0 32 32" className={`post-item__comments-like ${comment.liked ? 'active' : ''}`} xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.975 18.775L14.2688 29.3188C14.7375 29.7563 15.3562 30 16 30C16.6437 30 17.2625 29.7563 17.7313 29.3188L29.025 18.775C30.925 17.0062 32 14.525 32 11.9312V11.5687C32 7.2 28.8438 3.475 24.5375 2.75625C21.6875 2.28125 18.7875 3.2125 16.75 5.25L16 6L15.25 5.25C13.2125 3.2125 10.3125 2.28125 7.4625 2.75625C3.15625 3.475 0 7.2 0 11.5687V11.9312C0 14.525 1.075 17.0062 2.975 18.775Z" fill="black"/>
                          </svg></button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className='post-item__comments-empty'>There is no comments for now.</span>
              )} 
            </div>
            <div className='post-item__latest-post'>
                <div className='post-item__latest-post-title'>LATEST POST</div>
                <div className='post-item__latest-post-wrapper' >
                    <ImageBlog src={data.latestPost.image_url} alt={data.latestPost.title} />
                    <div className='post-item__latest-post-content'>
                      <div className='post-item__latest-post-head'>
                        <button onClick={handleChangePage}><h2>{data.latestPost.title}</h2></button>
                        <span>{parseCommentDate(data.latestPost.createdAt)}</span>
                      </div>
                      <div className='post-item__latest-post-text'>{data.latestPostText.content.split('\n').map((line, index) => (
                        <div key={index}><span>{line}</span></div>
                      ))}</div>
                      <div className='post-item__latest-post-link'><button className='post-item__latest-post-link-wrap' onClick={handleChangePage}><span>Read More</span><img className='post-item__latest-post-arrow' src={images.arrowLeft} /></button></div>
                    </div>
                  </div>
              </div>
          </motion.div>
          <Footer/>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div initial={{opacity: 0}} transition={{duration: 0.5}} animate={{opacity: 1}} exit={{opacity: 0}} className='loading-page'>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
        
      )}
    </div>
  )
}

export default BlogPost