import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from './../../axios';
import { AboutNumbersComponent, AwardsComponent, ClientsComponent, FilmographyComponent, HighlightedComponent, MainImagesComponent, PhotographyComponent, PostComponent, WorkWithComponent } from './Admin Components/index';
import './AdminStyle.scss';

const Admin = ({match}) => {
    const navigate = useNavigate();
    let { token } = useParams();
    const [accessGranted, setAccessGranted] = useState(false);

    useEffect(() => {
        let storedToken = localStorage.getItem('adminToken');

        axios.get(`verify?token=${storedToken}`).then((response) => {
            if(response.data.status !== "authorized") {
                navigate('/');
                setAccessGranted(false);
              } else {
                setAccessGranted(true);
              }
        });

        if(token !== storedToken) {
            navigate('/');
        }
    }, [])

    const logoutAdmin = () => {
      localStorage.removeItem('adminToken');
      navigate('/');
    }

  return (
    <div>
      {accessGranted && (
        <div className='admin__wrapper'>
          
          <div className='logout-admin' style={{marginTop: '16px'}}>
            <button onClick={logoutAdmin}>LOG OUT</button>
          </div>

        <MainImagesComponent />
        <AboutNumbersComponent />
        <WorkWithComponent/>
        <AwardsComponent/>
        <ClientsComponent/>
        <FilmographyComponent/>
        <PhotographyComponent/>
        <HighlightedComponent/>
        <PostComponent/>
      </div>
      )}
    </div>
  )
}

export default Admin