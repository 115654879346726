import React, { useState, useRef, createRef, useEffect } from 'react';
import axios from './../../../axios';
import FormData from 'form-data';
import { AdminMessagePopup } from './index';
import { images } from './../../../Constants';
import './../AdminStyle.scss';

const ClientsComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    let nameRef = createRef();
    const [deleted, setDeleted] = useState(false);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        axios.get(`/clientsGet`).then((response) => {
         setClients(response.data.clients);
        })
     }, []);

    const handleFileChange = (e) => {
        const image = e.target.files[0];
        setSelectedFile(image);
    }

    const submitClients = () => {
        setError(false);
        setCreated(false);

        if(nameRef.current.value && selectedFile) {

            const formData = new FormData();
            formData.append('image', selectedFile, selectedFile.name);
            formData.append('name', nameRef.current.value);

            axios.post(`/clientsPush`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setSelectedFile(null);
                    setTimeout(() => {
                        setCreated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        }
    }

    const deleteClients = (e) => {
        setError(false);
        setDeleted(false);

        axios.get(`/ClientsDelete?name=${e}`, ).then((response) => {
            if(response.data.status === 'ok') {
                setDeleted(true);
                setClients(response.data.clients);
                setTimeout(() => {
                    setDeleted(false);
                }, 2000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        })
    }

  return (
    <div className='admin__component'>
        <h1>Clients</h1>
      <div className='clients__form'>
        <label>Name</label>
        <input maxLength={128} ref={nameRef} type='text'/>
        <input type='file' accept='image/*' onChange={handleFileChange} />
        <button disabled={error || created || deleted} onClick={submitClients}>Submit</button>
        {created && 
            <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
        }
        {error &&
            <AdminMessagePopup message={"Something went wrong."} status={"error"} />
        }
        {deleted &&
            <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
        }
      </div> 
      <div className='clients__list'>
        {
          clients.map((x, key) => {
              return(
                  <div key={key}><span>{x}</span><button disabled={error || created || deleted} value={x} onClick={() => deleteClients(x)}><img src={images.xmark}/></button></div>
              )
          })
        }
      </div>
    </div>
  )
}

export default ClientsComponent