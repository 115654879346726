import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './AboutHeader.scss';
import { motion } from 'framer-motion'

const AboutHeader = (data) => {
    const [src, setSrc] = useState(data.src);
    const [getData, setGetData] = useState([]);

    useEffect(() => {
        axios.get('/aboutGetData').then((response) => {
            if(response.data.status === 'ok') {
                setGetData(response.data.aboutData);
            }
        })
    }, [src])

    let getYearsNumber = () => {
        const current = new Date();
        const currentYear = current.getFullYear();

        return currentYear - getData.founding_a_company;
    }

  return (
    <>
    {(src && getData != []) && (
           <div className='about-header'>
           <motion.div initial={{x: '-200px', opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.4, delay: 0.25, ease: 'easeOut'}} className='about-header__image-container'>
               <img src={src} alt='About Main Image' />
           </motion.div>
           <div className='about-header__content'>
               <div className='about-header__top-content'>
                   <motion.span initial={{x: '400px', opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.4, delay: 0.5, ease: 'easeOut'}}>About KamilFilms</motion.span>
                   <motion.div className='about-header__desc' initial={{x: '400px', opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.4, delay: 0.75, ease: 'easeOut'}}>
                       <p>{getData.head_desc}</p>
                   </motion.div>
               </div>
               <div className='about-header__numbers-container'>
                   <motion.div className='about-header__numbers-item' initial={{y: '50px', opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 0.5, delay: 1, ease: 'easeOut'}}>
                       <div className='about-header__number'>{getData.projects_number}</div>
                       <div className='about-header__number-text-wrapper'>
                           <b>Projects</b>
                           <span>With famous artists and corporations</span>
                       </div>
                   </motion.div>
                   <motion.div className='about-header__numbers-item' initial={{y: '50px', opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 0.5, delay: 1.1, ease: 'easeOut'}}>
                       <div className='about-header__number'>{getData.awards_number}</div>
                       <div className='about-header__number-text-wrapper'>
                           <b>Awards</b>
                           <span>For best films and photos</span>
                       </div>
                   </motion.div>
                   <motion.div className='about-header__numbers-item' initial={{y: '50px', opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 0.5, delay: 1.2, ease: 'easeOut'}}>
                       <div className='about-header__number'>{getYearsNumber()}</div>
                       <div className='about-header__number-text-wrapper'>
                           <b>Years</b>
                           <span>Of camera experience</span>
                       </div>
                   </motion.div>
               </div>
           </div>
       </div> 
    )}
    </>
  )
}

export default AboutHeader