import aboutCamera from './../Assets/About_Camera.png';
import aboutCameraCompressed from './../Assets/About_Camera_Compress.png';
import aboutFilmmaking from './../Assets/About_Filmmaking.png';
import aboutFilmmakingCompressed from './../Assets/About_Filmmaking_Compress.png';
import aboutKamilKrolak from './../Assets/About_Kamil_Krolak.png';
import aboutKamilKrolakCompressed from './../Assets/About_Kamil_Krolak_Compress.png';
import logowhite from './../Assets/KamilFilms-Logo-White.svg';
import logo from './../Assets/KamilFilms-Logo.svg';
import aboutMainImage from './../Assets/about_main_photos.png';
import aboutMainImageUnloaded from './../Assets/about_main_photos_unloaded.svg';
import arrowDown from './../Assets/angle-down-solid.svg';
import angleLeft from './../Assets/angle-left-solid.svg';
import angleRight from './../Assets/angle-right-solid.svg';
import arrowLeft from './../Assets/arrow-left-solid.svg';
import bars from './../Assets/bars-solid.svg';
import calendar from './../Assets/calendar-days-solid.svg';
import filmCamera from './../Assets/camera-reels-fill.svg';
import camera from './../Assets/camera-solid.svg';
import categoriesImageAllCompressed from './../Assets/categories/categories-image-all-min.jpg';
import categoriesImageAll from './../Assets/categories/categories-image-all.jpg';
import categoriesImageCorporateCompressed from './../Assets/categories/categories-image-corporate-min.jpg';
import categoriesImageCorporate from './../Assets/categories/categories-image-corporate.jpg';
import categoriesImageDanceCompressed from './../Assets/categories/categories-image-dance-min.jpg';
import categoriesImageDance from './../Assets/categories/categories-image-dance.jpg';
import categoriesImageDocumentaryCompressed from './../Assets/categories/categories-image-documentary-min.jpg';
import categoriesImageDocumentary from './../Assets/categories/categories-image-documentary.jpg';
import categoriesImageEventsCompressed from './../Assets/categories/categories-image-events-min.jpg';
import categoriesImageEvents from './../Assets/categories/categories-image-events.jpg';
import categoriesImageFictionCompressed from './../Assets/categories/categories-image-fiction-min.jpg';
import categoriesImageFiction from './../Assets/categories/categories-image-fiction.jpg';
import categoriesImageMusicCompressed from './../Assets/categories/categories-image-music-min.jpg';
import categoriesImageMusic from './../Assets/categories/categories-image-music.jpg';
import categoriesImageSportCompressed from './../Assets/categories/categories-image-sport-min.jpg';
import categoriesImageSport from './../Assets/categories/categories-image-sport.jpg';
import circleQuestion from './../Assets/circle-question-solid.svg';
import clapperboard from './../Assets/clapperboard-solid.svg';
import contactImageCompressed from './../Assets/contact-image-compressed.png';
import contactImage from './../Assets/contact-image.png';
import localization from './../Assets/earth-americas-solid.svg';
import envelope from './../Assets/envelope-solid.svg';
import settings from './../Assets/gear-solid.svg';
import spockHand from './../Assets/hand-spock-solid.svg';
import heart from './../Assets/heart-solid.svg';
import homeBest1 from './../Assets/home_best_1.png';
import homeBest2 from './../Assets/home_best_2.png';
import homeBest3 from './../Assets/home_best_3.png';
import homeBest4 from './../Assets/home_best_4.png';
import homeGallery1 from './../Assets/home_gallery_1.png';
import homeGallery2 from './../Assets/home_gallery_2.png';
import homeGallery3 from './../Assets/home_gallery_3.png';
import homeGallery4 from './../Assets/home_gallery_4.png';
import homeGallery5 from './../Assets/home_gallery_5.png';
import homeWho1 from './../Assets/home_who_1.jpg';
import homeWho1Compressed from './../Assets/home_who_1_compressed.jpg';
import homeWho2 from './../Assets/home_who_2.jpg';
import homeWho2Compressed from './../Assets/home_who_2_compressed.jpg';
import homeWhyUs from './../Assets/home_why_us.jpg';
import homeWhyUsCompressed from './../Assets/home_why_us_compressed.jpg';
import imdb from './../Assets/imdb.svg';
import instagram from './../Assets/instagram.svg';
import leftDoubleArrow from './../Assets/left-double-arrow.svg';
import shareLink from './../Assets/link-solid.svg';
import linkedin from './../Assets/linkedin-in.svg';
import location from './../Assets/location-dot-solid.svg';
import facebook from './../Assets/logo-facebook 1.svg';
import facebookColor from './../Assets/logo-facebook.svg';
import imdbColor from './../Assets/logo-imdb.svg';
import instagramColor from './../Assets/logo-instagram.svg';
import linkedinColor from './../Assets/logo-linkedin.svg';
import twitter from './../Assets/logo-twitter 1.svg';
import twitterColor from './../Assets/logo-twitter.svg';
import whatsappColor from './../Assets/logo-whatsapp.svg';
import youtube from './../Assets/logo-youtube 1.svg';
import youtubeColor from './../Assets/logo-youtube.svg';
import music from './../Assets/music-solid.svg';
import paintbrush from './../Assets/paintbrush-solid.svg';
import paperPlane from './../Assets/paper-plane-regular.svg';
import phone from './../Assets/phone-solid.svg';
import plus from './../Assets/plus-solid.svg';
import quote from './../Assets/quote-right-solid.svg';
import rightDoubleArrow from './../Assets/right-double-arrow.svg';
import shirt from './../Assets/shirt-solid.svg';
import volleyball from './../Assets/volleyball-solid.svg';
import xmark from './../Assets/xmark-solid.svg';


export default {
    logo,
    instagram,
    imdb,
    linkedin,
    facebook,
    twitter,
    youtube,
    xmark,
    bars,
    logowhite,
    phone,
    location,
    envelope,
    youtubeColor,
    instagramColor,
    imdbColor,
    twitterColor,
    facebookColor,
    whatsappColor,
    linkedinColor,
    arrowDown,
    arrowLeft,
    angleLeft,
    angleRight,
    localization,
    circleQuestion,
    paperPlane,
    camera,
    heart,
    shareLink,
    calendar,
    filmCamera,
    clapperboard,
    spockHand,
    music,
    paintbrush,
    shirt, 
    volleyball,
    leftDoubleArrow,
    rightDoubleArrow,
    settings,
    quote,
    plus,
    contactImage,
    contactImageCompressed,
    categoriesImageCorporate,
    categoriesImageCorporateCompressed,
    categoriesImageDance,
    categoriesImageDanceCompressed,
    categoriesImageDocumentary,
    categoriesImageDocumentaryCompressed,
    categoriesImageEvents,
    categoriesImageEventsCompressed,
    categoriesImageFiction,
    categoriesImageFictionCompressed,
    categoriesImageMusic,
    categoriesImageMusicCompressed,
    categoriesImageSport,
    categoriesImageSportCompressed,
    categoriesImageAll,
    categoriesImageAllCompressed,
    aboutMainImage,
    aboutMainImageUnloaded,
    aboutCamera,
    aboutCameraCompressed,
    aboutFilmmaking,
    aboutFilmmakingCompressed,
    aboutKamilKrolak,
    aboutKamilKrolakCompressed,
    homeGallery1,
    homeGallery2,
    homeGallery3,
    homeGallery4,
    homeGallery5,
    homeBest1,
    homeBest2,
    homeBest3,
    homeBest4,
    homeWho1,
    homeWho1Compressed,
    homeWho2,
    homeWho2Compressed,
    homeWhyUs,
    homeWhyUsCompressed,
}