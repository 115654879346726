import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './HomeWhyUs.scss';
import { easeOut, motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { ImageComponent } from './../../index';

const HomeWhyUs = (data) => {

  return (
    <div className='home-why'>
        <div className='home-why__content'>
            <h1>Why us?</h1>
            <p>{data.data.why_us}</p>
            <Link to="/filmography"><button>SEE OUR WORK</button></Link>
        </div>
        <div className='home-why__image-wrapper'>
            <div className='home-why__image'>
                <ImageComponent src={images.homeWhyUs} alt="Why us?" srccom={images.homeWhyUsCompressed} />
            </div>
        </div>
    </div> 
  )
}

export default HomeWhyUs