import FormData from 'form-data';
import React, { createRef, useEffect, useState } from 'react';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const FilmographyComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [filmography, setFilmography] = useState([]);
    const [tagOptions, setTagOptions] = useState(["Corporate & Business", "Events", "Art & Culture", "Entertainment & Music", "Sport & Recreation"]);
    const [selectedTagOptions, setSelectedTagOptions] = useState([]);
    const [currentOption, setCurrentOption] = useState("");

    let titleRef = createRef();
    let descRef = createRef();
    let shortDescRef = createRef();
    let localizationRef = createRef();
    let linkRef = createRef();
    let authorRef = createRef();

    useEffect(() => {
        axios.get(`/filmographyGet`).then((response) => {
            setFilmography(response.data.filmography);
        })
    }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitFilmography = () => {
        setError(false);
        setCreated(false);

        let tagString = ""
        tagString = selectedTagOptions.join(", ");

        if(titleRef.current.value && selectedFile && descRef.current.value && shortDescRef.current.value && localizationRef.current.value && linkRef.current.value && authorRef.current.value && tagString !== "") {

            const formData = new FormData();
            formData.append('image', selectedFile, selectedFile.name);
            formData.append('title', titleRef.current.value);
            formData.append('desc', descRef.current.value);
            formData.append('shortDesc', shortDescRef.current.value);
            formData.append('localization', localizationRef.current.value);
            formData.append('link', linkRef.current.value);
            formData.append('author', authorRef.current.value);
            formData.append('tags', tagString);

            axios.post(`/filmographyPush`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setSelectedFile(null);
                    setTimeout(() => {
                        setCreated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        }
    }

    const deleteFilmography = (e) => {
        setError(false);
        setDeleted(false);

        axios.get(`/filmographyDelete?name=${e}`, ).then((response) => {
            if(response.data.status === 'ok') {
                setDeleted(true);
                setFilmography(response.data.filmography);
                setTimeout(() => {
                    setDeleted(false);
                }, 2000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        })
    }

    const handleOptionChange = (e) => {
        const selectedOpt = e.target.value;
        setCurrentOption(selectedOpt);
        setSelectedTagOptions([...selectedTagOptions, selectedOpt]);
        
        const tagOpt = tagOptions.filter((option) => option !== selectedOpt);
        setTagOptions(tagOpt);
        setCurrentOption("");
    }

    const handleRemoveOption = (optionToRemove) => {
        const selectedOpt = selectedTagOptions.filter((option) => option !== optionToRemove);
        setSelectedTagOptions(selectedOpt);
        setTagOptions([...tagOptions, optionToRemove]);
    }

  return (
    <div className='admin__component'>
        <h1>Filmography</h1>
        <div className='filmography__form'>
            <label>Title</label>
            <input maxLength={255} ref={titleRef} type='text'/>
            <label>Short Description</label>
            <textarea maxLength={255} ref={shortDescRef} />
            <label>Description</label>
            <textarea ref={descRef} />
            <label>Localization</label>
            <input maxLength={128} ref={localizationRef} type='text'/>
            <label>Video Link</label>
            <input ref={linkRef} type='text'/>
            <label>Author</label>
            <input maxLength={64} ref={authorRef} type='text'/>
            <label>Tags</label>
            <select value={currentOption} name='tagOptions' onChange={handleOptionChange}>
                <option>Select Tags</option>
                {
                    tagOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))
                }
            </select>
            <div className='filmography__options'>
                {
                    selectedTagOptions.map((option, index) => (
                        <div key={index}>
                            <span>{option}</span>
                            <button disabled={error || created || deleted} onClick={() => handleRemoveOption(option)}><img src={images.xmark}/></button>
                        </div>
                    ))
                }
            </div>
            <label>Image</label>
            <input type='file' accept='image/*' onChange={handleFileChange} />
            <button disabled={error || created || deleted} onClick={submitFilmography}>Submit</button>
            {created &&
            <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
            }
            {error &&
                <AdminMessagePopup message={"Something went wrong."} status={"error"} />
            }
            {deleted &&
                <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
            }
        </div>
        <div className='filmography__list'>
            {
                filmography.map((x, key) => {
                    return(
                        <div key={key}><span>{x}</span><button disabled={error || created || deleted} value={x} onClick={() => deleteFilmography(x)}><img src={images.xmark}/></button></div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default FilmographyComponent