import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './HomeBest.scss';

const HomeBest = (data) => {
    const [isImage1Loaded, setIsImage1Loaded] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false);
    const [isImage3Loaded, setIsImage3Loaded] = useState(false);
    const [isImage4Loaded, setIsImage4Loaded] = useState(false);
    const [awardsData, setAwardsData] = useState();


    useEffect(() => {
        const img1 = new Image();  
        img1.src = images.homeBest1;
        img1.onload = () => setIsImage1Loaded(true);

        const img2 = new Image();  
        img2.src = images.homeBest2;
        img2.onload = () => setIsImage2Loaded(true);

        const img3 = new Image();  
        img3.src = images.homeBest3;
        img3.onload = () => setIsImage3Loaded(true);

        const img4 = new Image();  
        img4.src = images.homeBest4;
        img4.onload = () => setIsImage4Loaded(true);

        axios.get('/aboutGetAwards').then((response) => {
            if(response.data.status === 'ok') {
              setAwardsData(response.data.data);
            }
          })
    }, [])

  return (
    <>
    {(isImage1Loaded && isImage2Loaded && isImage3Loaded && isImage4Loaded && awardsData) ? (
        <div className='home-best'>
           <Link to='/filmography' className='home-best__row'>
                <img src={images.homeBest1} />
                <div className='home-best__content'>
                    <h1>Excellence in filmmaking</h1>
                    <div className='home-best__list'>
                        {data.data.panel_1.map((item, index) =>(
                            <span key={index}>{item}</span>
                        ))}
                    </div>
                </div>
           </Link>
           <Link to='/photography' className='home-best__row'>
                <img src={images.homeBest2} />
                <div className='home-best__content'>
                    <h1>Captivating Photography</h1>
                    <div className='home-best__list'>
                        {data.data.panel_2.map((item, index) =>(
                            <span key={index}>{item}</span>
                        ))}
                    </div>
                </div>
           </Link>
           <Link to='/about' className='home-best__row'>
                <img src={images.homeBest3} />
                <div className='home-best__content'>
                    <h1>Your Creative Content Creators</h1>
                    <div className='home-best__list'>
                        {data.data.panel_3.map((item, index) =>(
                            <span key={index}>{item}</span>
                        ))}
                    </div>
                </div>
           </Link>
           <Link to='/about' className='home-best__row'>
                <img src={images.homeBest4} />
                <div className='home-best__content'>
                    <div className='home-best__header'>
                        <span>Multiple Awards</span>
                        <span>in Video Production & Photography</span>
                    </div>
                    {awardsData.length > 2 ? (
                        <div className='home-best__awards'>
                            <img src={awardsData[0].image_url}/>
                            <img src={awardsData[1].image_url}/>
                            <img src={awardsData[2].image_url}/>
                        </div>
                    ) : (
                        <div className='home-best__awards'></div>
                    )}
                </div>
           </Link>
        </div> 
    ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
    )}
        
    </>
  )
}

export default HomeBest