import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { images } from './../../Constants';
import { motion, useScroll } from 'framer-motion'
import './NavBar.scss';

const NavBar = () => {
  const location = useLocation();

  const tabs = ['filmography', 'photography', 'blog', 'about', 'contact'];

  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const [isHome, setIsHome] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const { scrollYProgress } = useScroll();
  const [menuToggle, setMenuToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if(scrollTop === 0 && location.pathname === '/') {
        setIsScrolledToTop(true);
      } else {
        setIsScrolledToTop(false);
      }
      
      setScrollProgress(scrollYProgress.get());
    };
    if(location.pathname === '/') {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let changeToggle = () => {
    if (menuToggle) {setMenuToggle(false)} else setMenuToggle(true); 
  }

  return (
    <div className={isScrolledToTop && isHome ? 'navbar__top' : 'navbar__default'}>
      <div className='navbar__desktop'>
        <div className='navbar__tabs'>
          <Link to={'/'} className='navbar__tabs-logo'><img src={images.logo} alt='KamilFilms Logo' /></Link>
          <div className='navbar__tabs-elements'>
            {
              tabs.map((x) => (
                <Link to={`/${x}`} key={x} className={`navbar__tabs-el ${location.pathname.startsWith(`/${x}`) ? 'active' : ''}`}>
                  <span>{x.toUpperCase()}</span>
                </Link>
              ))
            }
          </div>
        </div>
        <div className='navbar__links'>
          <a href='https://www.facebook.com/KamilFilms' className='navbar__links-element'><img src={images.facebook} /></a>
          <a href='https://twitter.com/KamilFilms_' className='navbar__links-element'><img src={images.twitter} /></a>
          <a href='https://www.youtube.com/@KamilFilms' className='navbar__links-element'><img src={images.youtube} /></a>
          <a href='https://www.linkedin.com/in/kamilkrolak/' className='navbar__links-element'><img src={images.linkedin} /></a>
        </div>
        <div className='navbar__menu-open' onClick={changeToggle}>
          <img src={images.bars}/>
        </div>
      </div>
      <motion.div className='navbar__progress-bar' style={{scaleX: scrollProgress}} />
      <motion.div className={`navbar__menu ${menuToggle ? 'active' : ''}`} animate={{x: menuToggle ? 50 : 300}} transition={{duration: 1, ease: "easeIn", type: 'spring'}} initial={false}>
        <div className='navbar__menu-close' onClick={changeToggle}>
          <img src={images.xmark}/>
        </div>
        <div className='navbar__menu-elements'>
          {
            tabs.map((x) => (
              <Link to={`/${x}`} key={x} className={`navbar__menu-el ${location.pathname.startsWith(`/${x}`) ? 'active' : ''}`}>
                <span>{x.toUpperCase()}</span>
              </Link>
            ))
          }
        </div>
        <div className='navbar__menu-links'>
          <a href='https://www.facebook.com/KamilFilms' className='navbar__links-element'><img src={images.facebook} /></a>
          <a href='https://twitter.com/KamilFilms_' className='navbar__links-element'><img src={images.twitter} /></a>
          <a href='https://www.youtube.com/@KamilFilms' className='navbar__links-element'><img src={images.youtube} /></a>
          <a href='https://www.imdb.com/name/nm4821277/?ref_=fn_al_nm_1' className='navbar__links-element'><img src={images.imdb} /></a>
          <a href='https://www.instagram.com/kamilfilms/' className='navbar__links-element'><img src={images.instagram} /></a>
          <a href='https://www.linkedin.com/in/kamilkrolak/' className='navbar__links-element'><img src={images.linkedin} /></a>
        </div>
      </motion.div>
    </div>
  )
}

export default NavBar