import FormData from 'form-data';
import React, { createRef, useEffect, useState } from 'react';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const PhotographyComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedPhotoFiles, setSelectedPhotoFiles] = useState([]);
    const [alreadyExistingPhotoFiles,  setAlreadyExistingPhotoFiles] = useState([]);
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [photography, setPhotography] = useState([]);
    const [tagOptions, setTagOptions] = useState(["Events", "Art & Culture", "Sport & Recreation", "Fashion", "Behind the Scenes"]);
    const [selectedTagOptions, setSelectedTagOptions] = useState([]);
    const [currentOption, setCurrentOption] = useState("");
    const [isManageShown, setIsManageShown] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState("");

    let titleRef = createRef();
    let descRef = createRef();
    let shortDescRef = createRef();
    let authorRef = createRef();

    useEffect(() => {
        axios.get(`/photographyGet`).then((response) => {
         setPhotography(response.data.photography);
        })
     }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitPhotography = () => {
        setError(false);
        setCreated(false);

        let tagString = ""
        tagString = selectedTagOptions.join(", ");

        if(titleRef.current.value && selectedFile && descRef.current.value && shortDescRef.current.value && authorRef.current.value && tagString !== "") {

            const formData = new FormData();
            formData.append('image', selectedFile, selectedFile.name);
            formData.append('title', titleRef.current.value);
            formData.append('desc', descRef.current.value);
            formData.append('shortDesc', shortDescRef.current.value);
            formData.append('author', authorRef.current.value);
            formData.append('tags', tagString);

            axios.post(`/photographyPush`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setSelectedFile(null);
                    setTimeout(() => {
                        setCreated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        }
    }

    const deletePhotography = (e) => {
        setError(false);
        setDeleted(false);

        axios.get(`/photographyDelete?name=${e}`, ).then((response) => {
            if(response.data.status === 'ok') {
                setDeleted(true);
                setPhotography(response.data.photography);
                setSelectedGallery("");
                setIsManageShown(false);
                setTimeout(() => {
                    setDeleted(false);
                }, 2000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        })
    }

    const handleOptionChange = (e) => {
        const selectedOpt = e.target.value;
        setCurrentOption(selectedOpt);
        setSelectedTagOptions([...selectedTagOptions, selectedOpt]);
        
        const tagOpt = tagOptions.filter((option) => option !== selectedOpt);
        setTagOptions(tagOpt);
        setCurrentOption("");
    }

    const handleRemoveOption = (optionToRemove) => {
        const selectedOpt = selectedTagOptions.filter((option) => option !== optionToRemove);
        setSelectedTagOptions(selectedOpt);
        setTagOptions([...tagOptions, optionToRemove]);
    }

    const handlePhotoFileChange = (e) => {
        const files = e.target.files;
        const newFiles = Array.from(files);
        setSelectedPhotoFiles([...selectedPhotoFiles, ...newFiles]);
    }

    const managePhotography = (e) => {
        setSelectedPhotoFiles([]);
        setAlreadyExistingPhotoFiles([]);
        setSelectedGallery(e);
        setIsManageShown(true);
        axios.get(`/photographyGetGalleryContent?name=${e}`).then((response) => {
            if(response.data.status === 'ok') {
                setAlreadyExistingPhotoFiles(response.data.records);
            } 
        })
    }

    const handleRemoveImageFromGallery = (optionToRemove) => {
        const selectedOpt = selectedPhotoFiles.filter((option) => option.name !== optionToRemove.name);
        setSelectedPhotoFiles(selectedOpt);
    }

    const handleRemoveExistingImageFromGallery = (optionToRemove) => {
        const selectedOpt = alreadyExistingPhotoFiles.filter((option) => option !== optionToRemove);
        setAlreadyExistingPhotoFiles(selectedOpt);
    }

    const submitManageGallery = () => {
        setError(false);
        setCreated(false);

        const formData = new FormData();

        for(let i = 0; i < selectedPhotoFiles.length; i++) {
            formData.append(`image${i + 1}`, selectedPhotoFiles[i], selectedPhotoFiles[i].name);
        }
        formData.append('galleryTitle', selectedGallery);
        formData.append(`images`, alreadyExistingPhotoFiles);

        axios.post(`/photographyUpdate`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }).then((response) => {
            if(response.data.status === 'ok') {
                setUpdated(true);
                setSelectedPhotoFiles([]);
                setTimeout(() => {
                    setUpdated(false);
                    window.location.reload();
                }, 1500);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        });
    }

  return (
    <div className='admin__component'>
        <h1>Photography</h1>
        <div className='photography__form'>
            <label>Title</label>
            <input maxLength={255} ref={titleRef} type='text'/>
            <label>Short Description</label>
            <textarea maxLength={255} ref={shortDescRef} />
            <label>Description</label>
            <textarea ref={descRef} />
            <label>Author</label>
            <input maxLength={64} ref={authorRef} type='text'/>
            <label>Tags</label>
            <select value={currentOption} name='tagOptions' onChange={handleOptionChange}>
                <option>Select Tags</option>
                {
                    tagOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))
                }
            </select>
            <div className='photography__options'>
                {
                    selectedTagOptions.map((option, index) => (
                        <div key={index}>
                            {option}
                            <button disabled={error || created || deleted || updated} onClick={() => handleRemoveOption(option)}><img src={images.xmark}/></button>
                        </div>
                    ))
                }
            </div>
            <label>Image</label>
            <input type='file' accept='image/*' onChange={handleFileChange} />
            <button disabled={error || created || deleted || updated} onClick={submitPhotography}>Submit</button>
            {created && 
                <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
            }
            {error &&
                <AdminMessagePopup message={"Something went wrong."} status={"error"} />
            }
            {deleted &&
                <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
            }
            {updated &&
                <AdminMessagePopup message={"Object updated successfully!"} status={"success"} />
            }
        </div>
        <div className='photography__list'>
            <span>Your galleries</span>
            {
                photography.map((x, key) => {
                    return(
                        <div key={key}><span>{x}</span><button disabled={error || created || deleted || updated} value={x} onClick={() => managePhotography(x)}><img src={images.settings}/></button><button value={x} onClick={() => deletePhotography(x)}><img src={images.xmark}/></button></div>
                    )
                })
            }
        </div>
        {isManageShown &&
            <div className='photography__management'>
                <span>Manage gallery: </span><b>{selectedGallery}</b>
                <input type='file' accept='image/*' multiple onChange={handlePhotoFileChange} />
                <div className='photography__image-list'>
                    <span>Images:</span>
                    {
                        alreadyExistingPhotoFiles.map((x, index) => (
                            <div key={index}>{x.split('_')[3]}<button disabled={error || created || deleted || updated} value={x} onClick={() => handleRemoveExistingImageFromGallery(x)}><img src={images.xmark}/></button></div>
                        ))
                    }
                    {
                        selectedPhotoFiles.map((x, index) => (
                            <div key={index}>{x.name}<button disabled={error || created || deleted || updated} value={x.name} onClick={() => handleRemoveImageFromGallery(x)}><img src={images.xmark}/></button></div>
                        ))
                    }
                </div>
                <button disabled={error || created || deleted || updated} onClick={submitManageGallery}>Submit</button>
            </div>
        }
    </div>
  )
}

export default PhotographyComponent