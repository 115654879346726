import React, { useState } from 'react';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const MainImagesComponent = () => {
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [selectedPhotoFiles, setSelectedPhotoFiles] = useState([]);

     const submitImageChange = () => {
        setError(false);
        setCreated(false);
        if(selectedPhotoFiles.length == 5) {
            const formData = new FormData();

            for(let i = 0; i < selectedPhotoFiles.length; i++) {
                formData.append(`image${i + 1}`, selectedPhotoFiles[i], selectedPhotoFiles[i].name);
            }
    
            axios.post(`/headerImagesUpdate`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setUpdated(true);
                    setSelectedPhotoFiles([]);
                    setTimeout(() => {
                        setUpdated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        } else {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
    }

    const handleImagesFilesChange = (e) => {
        const files = e.target.files;
        const newFiles = Array.from(files);
        if(newFiles.length == 5) {
            setSelectedPhotoFiles(newFiles);
        } else {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
    }

  return (
    <div className='admin__component'>
        <h1>Header Images</h1>
        <div className='header-images__form'>
        <label>Select Images (Exactly 5)</label>
        <input type='file' accept='image/*' multiple onChange={handleImagesFilesChange} />
        <button disabled={error || created || deleted || updated} onClick={submitImageChange}>Submit</button>
        {created && 
            <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
        }
        {updated && 
            <AdminMessagePopup message={"Object updated successfully!"} status={"success"} />
        }
        {error &&
            <AdminMessagePopup message={"Something went wrong."} status={"error"} />
        }
        {deleted &&
            <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
        }
      </div> 
    </div>
  )
}

export default MainImagesComponent