import React, { useState, useEffect } from 'react';
import './ImageBlog.scss';
import { ColorRing } from 'react-loader-spinner';

const ImageBlog = ({ src, alt }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setIsImageLoaded(true);
    };
  }, [src]);

  return (
    <div className={`image-blog`}>
        {isImageLoaded ? (
            <img src={src} alt={alt}/>
        ) : (
            <div className='image-blog__placeholder'>
              <ColorRing
                height="128"
                width="128"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
              />
            </div>
        )}
      
      
    </div>
  );
};

export default ImageBlog;