import React, { useState, useEffect, useRef } from 'react';
import { images } from './../../Constants';
import './Contact.scss';
import { delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import {NavBar, Footer, ImageComponent} from '../index';

const Contact = () => {
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputSubject, setInputSubject] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [envData, setEnvData] = useState([]);
  const [fontLoaded, setFontLoaded] = useState(false);

  let nameRef = useRef();
  let emailRef = useRef();
  let subjectRef = useRef();
  let messageRef = useRef();

  const formHead = {
    hidden: {},
    show: {
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.25
      }
    }
  }

  const formHeadItem = {
    hidden: { opacity: 0, x: -100 },
    show: { opacity: 1, x: 0,
      transition: {
        type: 'spring',
        ease: 'easeOut',
        duration: 1.5,
        stiffness: 200
      }
    }
  }

  const socialHead = {
    hidden: {},
    show: {
      transition: {
        delayChildren: 1.75,
        staggerChildren: 0.2,
      }
    }
  }

  const socialItem = {
    hidden: { opacity: 0, y: 50},
    show: { opacity: 1, y: 0, 
      transition: {
        type: 'spring',
        ease: 'easeIn',
        duration: 1,
        stiffness: 100,
      }
    }
  }

  let social = [
    {id: 1, name: 'Youtube', logo: images.youtubeColor, link: 'https://www.youtube.com/@KamilFilms'},
    {id: 2, name: 'Facebook', logo: images.facebookColor, link: 'https://www.facebook.com/KamilFilms'},
    {id: 3, name: 'Instagram', logo: images.instagramColor, link: 'https://www.instagram.com/kamilfilms/'},
    {id: 4, name: 'Twitter', logo: images.twitterColor, link: 'https://twitter.com/KamilFilms_'},
    {id: 5, name: 'IMDB', logo: images.imdbColor, link: 'https://www.imdb.com/name/nm4821277/?ref_=fn_al_nm_1'},
    {id: 6, name: 'LinkedIn', logo: images.linkedinColor, link: 'https://www.linkedin.com/in/kamilkrolak/'}
  ];

  let handleInputChange = (inputNumber, event) => {
    const value = event.target.value;

    switch(inputNumber) {
      case 1: setInputName(value); nameRef.current.style.border = 'none';
        break;
      case 2: setInputEmail(value); emailRef.current.style.border = 'none'
        break;
      case 3: setInputSubject(value); subjectRef.current.style.border = 'none'
        break;
      case 4: setInputMessage(value); messageRef.current.style.border = 'none'
        break;
      default:
        break;
    }
  }

  let handleSend = () => {
    if(inputName === '') {
      nameRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    } 
    if(inputEmail === '') {
      emailRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    } 
    if(inputSubject === '') {
      subjectRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    } 
    if(inputMessage === '') {
      messageRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    }
    if(inputName !== '' && inputEmail !== '' && inputSubject !== '' && inputMessage !== '') {
      axios.post(`/sendmessage`, {name: inputName, email: inputEmail, subject: inputSubject, message: inputMessage})
      .then((response) => {
        if(response.data.status === 'ok') {
          setMessageSent(true);
          setTimeout(() => {
            setMessageSent(false);
          }, 3000)
        } else {
          setWrongEmail(true);
          setTimeout(() => {
            setWrongEmail(false);
          }, 3000)
        }
      });
    }
  }

  useEffect(() => {
    document.title = "KamilFilms";
    axios.get(`/getEnvData`).then((response) => {
      setEnvData(response.data)
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });

  }, [])

  return (
    <div>
      {fontLoaded ? (
        <div>
          <NavBar/>
          <div className='contact'>
            <div className='contact__form'>
              <motion.div variants={formHead} initial='hidden' animate='show' className='contact__form-head'>
                <motion.span variants={formHeadItem}>YOU HAVE A QUESTION?</motion.span>
                <motion.span variants={formHeadItem}>You can contact us right here!</motion.span>
              </motion.div>
              <motion.div initial={{opacity: 0, y: 20}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5, delay: 0.75}} className='contact__form-form'>
                <div className='contact__form-first-row'>
                  <div>
                    <label>Your Name</label>
                    <input type='text' ref={nameRef} placeholder='Name' value={inputName} className='contact__form-input-name' onChange={(e) => handleInputChange(1, e)}/>
                  </div>
                  <div>
                    <label>Your E-Mail Address</label>
                    <input type='text' ref={emailRef} placeholder='Email' value={inputEmail} className='contact__form-input-email' onChange={(e) => handleInputChange(2, e)}/>
                  </div>
                </div>
                <label>Subject</label>
                <input type='text' ref={subjectRef} placeholder='Subject' value={inputSubject} className='contact__form-input-subject' onChange={(e) => handleInputChange(3, e)}/>
                <label>Message</label>
                <textarea placeholder='Message' ref={messageRef} value={inputMessage} className='contact__form-input-message' onChange={(e) => handleInputChange(4, e)}/>
                <button onClick={handleSend}>SEND MESSAGE</button>
              </motion.div>
              <motion.div animate={messageSent || wrongEmail ? {  y: 0, opacity: 1 } : {}} initial={{ opacity: 0, y: 10 }} transition={{ ease: easeOut, duration: 0.1 }} className='contact__alert'>{wrongEmail ? 'Something Went Wrong' : 'Message Sent'}</motion.div>
            </div>
            <div className='contact__info'>
              <div className='contact__info-find-us'>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.25, delay: 1.25}} className='contact__info-data'>
                  <span>How to find us?</span>
                  <a href={`tel:${envData.tel}`} className='contact__info-data-element'>
                    <img src={images.phone} />
                    <span>{envData.tel}</span>
                  </a>
                  <a href={envData.localization} className='contact__info-data-element'>
                    <img src={images.location} />
                    <span>{envData.localization}</span>
                  </a>
                  <a href={`mailto:${envData.email}`} className='contact__info-data-element'>
                    <img src={images.envelope} />
                    <span>{envData.email}</span>
                  </a>
                </motion.div>
                <motion.div className='contact__image' initial={{x: 500}} animate={{x: 0}} transition={{ duration: 0.75, type: 'spring', delay: 1 }}>
                  <ImageComponent src={images.contactImage} alt='KamilFilms' srccom={images.contactImageCompressed} />
                </motion.div>
              </div>
              <div className='contact__info-social'>
                <motion.div className='contact__info-head' initial={{opacity: 0, y: 50}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5, delay: 1.5, ease: easeOut, type: 'spring', stiffness: 100 }}><span>Check out our social media</span></motion.div>
                <motion.div variants={socialHead} initial='hidden' animate='show' className='contact__info-social-links'>
                  {
                    social.map((x) => (
                      <motion.a variants={socialItem} href={x.link} key={x.id} className='contact__info-social-element'>
                        <img src={x.logo} />
                        <span>{x.name}</span>
                      </motion.a>
                    ))
                  }
                </motion.div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
      )}
    </div>
  )
}

export default Contact