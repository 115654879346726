import React, { useEffect, useState, useRef } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './HomeBlog.scss';
import { easeOut, motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { ImageBlog } from './../../index';

const HomeBlog = () => {
    const [blogData, setBlogData] = useState();

    useEffect(() => {
        axios.get('/getHomeBlogPosts').then((response) => {
            if(response.data.status === 'ok') {
                setBlogData(response.data.posts);
            }
        })
    }, [])

    let checkIfData = () => {
        try{if(blogData.length > 0) return true;} catch(err) {return false}
    }

    let parseDate = (e) => {
        if(e !== '') {
            const date = e;
            const transformedDate = date.split('T')[0];
            const partsDate = transformedDate.split('-');
            const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
            return reversedDate;  
        } else {
            return e
        }
      }

  return (
    <>
    {(checkIfData()) ? (
        <div className='home-blog'>
            <h1>OUR LATEST BLOG POSTS</h1>
            <div className='home-blog__container'>
                <div className='home-blog__main-post'>
                    <div className='home-blog__item'>
                        <ImageBlog src={blogData[0].image_url} alt={blogData[0].title} />
                        <div className='home-blog__item-content'>
                          <div className='home-blog__item-head'>
                            <Link to={`/blog/post/${blogData[0].title}`}><h1>{blogData[0].title}</h1></Link>
                            <span>{parseDate(blogData[0].created_at)}</span>
                          </div>
                          <div className='home-blog__item-text'>{blogData[0].long_desc.split('\n').map((line, index) => (
                            <div key={index}><span>{line}</span></div>
                          ))}</div>
                          <div className='home-blog__item-links'>
                            <Link to={`/blog/post/${blogData[0].title}`}><button className='home-blog__item-button-one'>READ MORE</button></Link>
                            <Link to={`/blog`}><button className='home-blog__item-button-two'>SEE OUR BLOG HERE</button></Link>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
    )}
    </>
    
  )
}

export default HomeBlog