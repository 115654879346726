import React, {useState, useEffect} from 'react';
import {NavBar, Footer, ImageBlog} from '../index';
import {images} from './../../Constants';
import './Blog.scss';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import { useParams } from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import { AnimatePresence, PresenceContext, motion } from 'framer-motion';

const Blog = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const {pageParamNumber} = useParams();
  const pageNumber = pageParamNumber || '1';
  const [blogPostList, setBlogPostList] = useState([]);
  const [postAmount, setPostAmount] = useState(0);
  const totalPages = Math.ceil(postAmount / 10);
  const pageNumbers = Array.from({length: totalPages}, (_, index) => index + 1);
  
  useEffect(() => {
    document.title = "KamilFilms";
    const pageNumberFunc = pageParamNumber || '1';
    axios.get(`/getBlogPostList?page=${pageNumberFunc}`).then(async(response) => {
      if(response.data.status === 'ok') {
        setBlogPostList(response.data.posts);
        setPostAmount(response.data.count);
      }
    })
    
  }, [pageParamNumber])

  useEffect(() => {

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });

  }, [])

  let parseDate = (e) => {
    if(e !== '') {
        const date = e;
        const transformedDate = date.split('T')[0];
        const partsDate = transformedDate.split('-');
        const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
        return reversedDate;  
    } else {
        return e
    }
  }

  let showPageNumberSlider = () => {
    let result = [];
    if(pageNumber == 1) {
      result.push(<div className='blog__page-arrow-disable-wrapper' key='previous'><img src={images.angleLeft} /></div>)
    } else {
      result.push(<div className='blog__page-arrow-wrapper' key='previous' onClick={() => window.location.href = `/blog/${pageNumber - 1}`}><img src={images.angleLeft} /></div>);
    }

    if(pageNumbers.length > 1) {
      result.push(<div onClick={() => window.location.href = `/blog/${1}`} key={1} className={pageNumber == 1 ? 'blog__page-number active' : 'blog__page-number'}>{1}</div>);
    }

    if(pageNumbers.length > 2) {
      result.push(<div onClick={() => window.location.href = `/blog/${2}`} key={2} className={pageNumber == 2 ? 'blog__page-number active' : 'blog__page-number'}>{2}</div>);
    }

    switch (pageNumber) {
      case 1:
      case 2:
      case 3:
      case 4:
        for (let i = 2; i < Math.min(5, pageNumbers.length - 1); i++) {
          result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[i]}`} key={pageNumbers[i]} className={pageNumber == pageNumbers[i] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[i]}</div>);
        }
        if(pageNumbers.length > 6) {
          result.push(<div className='blog__post-page-separator' key={'separator'}>...</div>);
        } 
        break;

      case pageNumbers.length:
        result.push(<div onClick={() => window.location.href = `/blog/${3}`} key={3} className={pageNumber == 3 ? 'blog__page-number active' : 'blog__page-number'}>{3}</div>);
        result.push(<div onClick={() => window.location.href = `/blog/${4}`} key={4} className={pageNumber == 4 ? 'blog__page-number active' : 'blog__page-number'}>{4}</div>);
        if(pageNumbers.length > 6) {
          result.push(<div className='blog__post-page-separator' key={'separator'}>...</div>);
        }
        if(pageNumbers.length != 5) {
          result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumbers.length - 2]}`} key={pageNumbers[pageNumbers.length - 2]} className={pageNumber == pageNumbers[pageNumbers.length - 2] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumbers.length - 2]}</div>);
        }
        break;

      case pageNumbers.length - 1:
        result.push(<div onClick={() => window.location.href = `/blog/${3}`} key={3} className={pageNumber == 3 ? 'blog__page-number active' : 'blog__page-number'}>{3}</div>);
        if(pageNumbers.length > 6) {
          result.push(<div className='blog__post-page-separator' key={'separator'}>...</div>);
        } 
        result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumbers.length - 3]}`} key={pageNumbers[pageNumbers.length - 3]} className={pageNumber == pageNumbers[pageNumbers.length - 3] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumbers.length - 3]}</div>);
        result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumbers.length - 2]}`} key={pageNumbers[pageNumbers.length - 2]} className={pageNumber == pageNumbers[pageNumbers.length - 2] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumbers.length - 2]}</div>);
        break;

      default:
        if(pageNumbers.length > 6) {
          result.push(<div className='blog__post-page-separator' key={'separator'}>...</div>);
        }
        if (pageNumber >= 2 && pageNumber <= pageNumbers.length - 2) {
          result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumber - 2]}`} key={pageNumbers[pageNumber - 2]} className={pageNumber == pageNumbers[pageNumber - 2] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumber - 2]}</div>);
          result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumber - 1]}`} key={pageNumbers[pageNumber - 1]} className={pageNumber == pageNumbers[pageNumber - 1] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumber - 1]}</div>);
          result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumber]}`} key={pageNumbers[pageNumber]} className={pageNumber == pageNumbers[pageNumber] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumber]}</div>);
        }
        if(pageNumbers.length > 6 && pageNumber + 2 != pageNumbers.length) {
          result.push(<div className='blog__post-page-separator' key={'separator2'}>...</div>);
        }
        break;
    }
    
    result.push(<div onClick={() => window.location.href = `/blog/${pageNumbers[pageNumbers.length - 1]}`} key={pageNumbers[pageNumbers.length - 1]} className={pageNumber == pageNumbers[pageNumbers.length - 1] ? 'blog__page-number active' : 'blog__page-number'}>{pageNumbers[pageNumbers.length - 1]}</div>);

    if(pageNumber == pageNumbers.length) {
      result.push(<div className='blog__page-arrow-disable-wrapper' key='next'><img src={images.angleRight} /></div>)
    } else {
      result.push(<div className='blog__page-arrow-wrapper' key='next' onClick={() => window.location.href = `/blog/${parseInt(pageNumber) + 1}`}><img src={images.angleRight} /></div>);
    }

    return result;
  }

  return (
    <div>
      {(fontLoaded && blogPostList && postAmount != 0) ? (
        <div>
          <NavBar/>
          <AnimatePresence>
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}} className='blog'>
            <div className='blog__header'>BLOG</div>
            <div className='blog__posts'>
              {
                blogPostList.map((post, index) => (
                  <div className='blog__post-item' key={index}>
                    <ImageBlog src={post.image_url} alt={post.title} />
                    <div className='blog__post-item-content'>
                      <div className='blog__post-item-head'>
                        <Link to={`/blog/post/${post.title}`}><h1>{post.title}</h1></Link>
                        <span>{parseDate(post.created_at)}</span>
                      </div>
                      <div className='blog__post-text'>{post.long_desc.split('\n').map((line, index) => (
                        <div key={index}><span>{line}</span></div>
                      ))}</div>
                      <div className='blog__post-link'><Link className='blog__post-link-wrap' to={`/blog/post/${post.title}`}><span>Read More</span><img className='blog__post-arrow' src={images.arrowLeft} /></Link></div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className='blog__pages'>
              {showPageNumberSlider()}
            </div>
          </motion.div>
          </AnimatePresence>
          <Footer/>
        </div>
      ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
      )}
    </div>
  )
};

export default Blog;