import React, { useState, useRef, createRef, useEffect } from 'react';
import axios from './../../../axios';
import './AdminMessagePopup.scss';
import { AnimatePresence, PresenceContext, motion } from 'framer-motion';

const AdminMessagePopup = ({message, status}) => {

  return (
    <motion.div initial={{y: 100}} animate={{y: 0}} exit={{y: 100}} transition={{duration: 0.4}} className='admin__popup'>
        <AnimatePresence>
            <div className={`admin__popup-content ${status}`}>
                {message}
            </div>
        </AnimatePresence>
    </motion.div>
  )
}

export default AdminMessagePopup