import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './HomeWho.scss';
import { easeOut, motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { ImageComponent } from './../../index';

const HomeWho = (data) => {

  return (
    <div className='home-who'>
        <div className='home-who_content-head-small'>
            <h1>Who are we?</h1>
            <div className='home-who__quote'>
                <p>{data.data.who_desc}</p>
                <span>Kamil Krolak</span>
            </div>
        </div>
        <div className='home-who__image-container'>
            <div className='home-who__image'><ImageComponent src={images.homeWho1} alt='Kamil Films Who Are We? Image 1' srccom={images.homeWho1Compressed} /></div>
            <div className='home-who__image'><ImageComponent src={images.homeWho2} alt='Kamil Films Who Are We? Image 2' srccom={images.homeWho2Compressed} /></div>
        </div>
        <div className='home-who__content'>
            <div className='home-who_content-head'>
                <h1>Who are we?</h1>
                <div className='home-who__quote'>
                    <p>{data.data.quote}</p>
                    <span>Kamil Krolak</span>
                </div>
            </div>
            <p className='home-who__desc'>{data.data.who_desc}</p>
            <div className='home-who__list'>
                <p>We specialize on multiple fields, such as:</p>
                <ul>
                    {data.data.specialization_list.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <Link to='/about'><button className='home-who__more'>READ MORE ABOUT US</button></Link>
        </div>
    </div> 
  )
}

export default HomeWho