import React, { useEffect, useState, useRef } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './HomeOurWork.scss';
import { easeOut, motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { ImageComponent } from './../../index';

const HomeOurWork = () => {
    const [workData, setWorkData] = useState();
    const [tagList, setTagList] = useState();
    const [isMore, setIsMore] = useState(true);
    const componentRef = useRef(null);

    useEffect(() => {
        axios.get('/homeGetOurWork').then((response) => {
            if(response.data.status === 'ok') {
                setTagList(response.data.tags);
                setWorkData(response.data.data);
            }
        })
    }, [])

    let checkIfData = () => {
        try{if(workData.length > 0) return true;} catch(err) {return false}
    }

    let changeAmount = () => {
        if(!isMore) {
            componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setIsMore(!isMore);
    }

  return (
    <>
    {(checkIfData() && tagList) ? (
        <div ref={componentRef} className='home-work'>
            <div className='home-work__head'>
                <h1>OUR WORK</h1>
                <p>Over the years of our activity, we are proud of the work we do with such great passion and enthusiasm. View a collection of our work here or go to</p>
                <div><Link to='/filmography'>Filmography</Link> / <Link to='photography'>Photography</Link></div>
            </div>
            <div className='home-work__container'>
                {workData.map((item, index) => {
                    if((index < 3 || !isMore) && item.result.length >= 1) {
                        return (
                            <div key={index} className='home-work__category'>
                                <div className='home-work__top-head-container'>
                                    <h1>{item.tag}</h1>
                                    <Link to={'filmography'} state={{ actTag: item.tag }} className='home-work__see-all'>See all</Link>
                                </div>
                                <div className={`home-work__category-elements ${item.result.length == 1 ? 'empty' : ''}`}>
                                    <button className='home-work__main-image'>
                                        <img src={item.result[0].image_url} alt={item.result[0].dataValues.title}/>
                                        <h2>{item.result[0].dataValues.title}</h2>
                                        <p>{item.result[0].dataValues.short_desc}</p>
                                        <Link to={item.result[0].dataValues.localization !== undefined ? `/filmography/${item.result[0].dataValues.title}` : `/photography/${item.result[0].dataValues.title}`}><button>SEE MORE</button></Link>
                                    </button>
                                    <div className='home-work__other-stuff'>
                                        <div className='home-work__other-head-wrapper'>
                                            <h1>{item.tag}</h1>
                                            <Link to={'filmography'} state={{ actTag: item.tag }} className='home-work__see-all'>See all</Link>
                                        </div>
                                        <div className='home-work__smaller-images'>
                                            {item.result.length >= 2 && (
                                                <button className='home-work__small-image'>
                                                    <img src={item.result[1].image_url} alt={item.result[1].dataValues.title}/>
                                                    <h2>{item.result[1].dataValues.title}</h2>
                                                    <Link to={item.result[1].dataValues.localization !== undefined ? `/filmography/${item.result[1].dataValues.title}` : `/photography/${item.result[1].dataValues.title}`}><button>SEE MORE</button></Link>
                                                </button>
                                            )}
                                            {item.result.length >= 3 && (
                                                <button className='home-work__small-image'>
                                                    <img src={item.result[2].image_url} alt={item.result[2].dataValues.title}/>
                                                    <h2>{item.result[2].dataValues.title}</h2>
                                                    <Link to={item.result[2].dataValues.localization !== undefined ? `/filmography/${item.result[2].dataValues.title}` : `/photography/${item.result[2].dataValues.title}`}><button>SEE MORE</button></Link>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            <button onClick={changeAmount} className='home-work__see-more'>{!isMore ? 'SEE LESS' : 'SEE MORE'}</button>
        </div> 
    ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
    )}
    </>
    
  )
}

export default HomeOurWork