import React, { useState, useEffect } from 'react';

const ImageComponent = ({ src, alt, srccom }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setIsLoading(false);
      setIsImageLoaded(true);
    };
  }, [src]);

  const placeholderStyle = {
    filter: 'blur(4px)', // Ustawienie efektu wyblurowania
    transition: 'opacity 0.5s',
    opacity: isLoading ? 1 : 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const imageStyle = {
    opacity: isImageLoaded ? 1 : 0,
    transition: 'opacity 0.5s',
    width: '100%',
    height: 'auto',
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
      <img
        src={src}
        alt={alt}
        style={{ ...imageStyle, filter: 'blur(0px)' }} // Usunięcie efektu wyblurowania po załadowaniu
      />
      <img
        src={isImageLoaded ? src : srccom}
        alt={alt}
        style={placeholderStyle}
        onLoad={() => setIsImageLoaded(true)}
      />
    </div>
  );
};

export default ImageComponent;