import FormData from 'form-data';
import React, { useEffect, useState } from 'react';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const PostComponent = () => {
    const [title, setTitle] = useState('');
    const [mainImage, setMainImage] = useState(null);
    const [isVideo, setIsVideo] = useState(false);
    const [video, setVideo] = useState(null);
    const [text, setText] = useState('');
    const [imagesPost, setImagesPost] = useState([{ image: null, text: '' }]);
    const [created, setCreated] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [fbError, setFbError] = useState(false);
    const [postList, setPostList] = useState([]);
    const [isFacebookPostChecked, setIsFacebookPostChecked] = useState(false);
    
    useEffect(() => {
      axios.get(`/getPosts`).then((response) => {
        setPostList(response.data.posts);
      })
    }, [])
  
    const handleMainImageChange = (e) => {
      setMainImage(e.target.files[0]);
    };
  
    const handleVideoChange = (e) => {
      setVideo(e.target.files[0]);
    };
  
    const handleTextChange = (e, index) => {
      const updatedImages = [...imagesPost];
      updatedImages[index].text = e.target.value;
      setImagesPost(updatedImages);
    };

    const handleImageChange = (e, index) => {
        const updatedImages = [...imagesPost];
        updatedImages[index].image = e.target.files[0];
        setImagesPost(updatedImages);
    };
  
    const handleAddImage = () => {
      setImagesPost([...imagesPost, { image: null, text: '' }]);
    };

    const handleDeleteRow = (e, index) => {
        const newArray = [...imagesPost];
        newArray.splice(index, 1);
        setImagesPost(newArray);
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(false);
      setDeleted(false);

      if(title !== '' && mainImage && imagesPost.length > 0 && ((video !== '' && text !== '') || (imagesPost.every((post) => post.text.trim() !== '') && imagesPost.every((post) => post.image)))) {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('mainImage', mainImage);
        formData.append('isVideo', isVideo);
        formData.append('isFacebookChecked', isFacebookPostChecked);
        formData.append('fbAccessToken', accessToken);
        if (isVideo) {
          formData.append('video', video);
          formData.append('text', text);
        } else {
          imagesPost.forEach((image, index) => {
            formData.append('images', image.image);
            formData.append('text', image.text);
          });
        }
    
        try {
          axios.post('/pushPost', formData, {
              headers: {
                  'content-type': 'multipart/form-data',
              },
          }).then((response) => {
              if(response.data.status === 'ok') {
                setImagesPost([{ image: null, text: '' }]);
                setText('');
                setVideo(null);

                if(response.data.facebook_status == true) {
                  setCreated(true);
                  setTimeout(() => {
                    setCreated(false);
                    window.location.reload();
                  }, 1500);
                } else {
                  setFbError(true);
                  setTimeout(() => {
                    setFbError(false);
                    window.location.reload();
                  }, 2000);
                }
              } else {
                  setError(true);
                  setTimeout(() => { setError(false); }, 2000);
              }
          });
        } catch (error) {
          setError(true)
          setTimeout(() => { setError(false); }, 2000);
        }
      } else {
        setError(true);
        setTimeout(() => { setError(false); }, 2000);
      }
    };
    
    let deletePost = (e) => {
      setError(false);
      setDeleted(false);

      axios.get(`/deletePost?name=${e}`, ).then((response) => {
          if(response.data.status === 'ok') {
              setDeleted(true);
              setPostList(response.data.posts);
              setTimeout(() => { setDeleted(false); }, 2000);
          } else {
              setError(true);
              setTimeout(() => { setError(false); }, 2000);
          }
      })
    }
  
    return (
      <div className='admin__component'>
        <h1>Posts</h1>
        <div className='posts__form'>
        <label>Title:</label>
        <input maxLength={256} type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
  
        <label>Main Image:</label>
        <input type="file" accept="image/*" onChange={handleMainImageChange} />
  
        <label>Post Type:</label>
        <select onChange={(e) => {setIsVideo(e.target.value === 'video'); setVideo(null); setText(''); setImagesPost([{ image: null, text: '' }]); setIsFacebookPostChecked(false)}}>
          <option value="image">Image</option>
          <option value="video">Video</option>
        </select>
        
  
        {isVideo ? (
          <div className='posts__add-video-element'>
            <label>Video:</label>
            <input type="text" value={video} onChange={(e) => setVideo(e.target.value)} />
  
            <label>Text:</label>
            <textarea value={text} onChange={(e) => setText(e.target.value)} />
            
          </div>
        ) : (
          <div className='posts__add-image-element'>
            {imagesPost.map((image, index) => (
              <div key={index}>
                <label>Image {index + 1}:</label>
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
  
                <label>Text {index + 1}:</label>
                <textarea value={image.text} onChange={(e) => handleTextChange(e, index)} />
                
                <button disabled={imagesPost.length <= 1} onClick={(e) => handleDeleteRow(e, index)}><img src={images.xmark}/></button>
              </div>
            ))}
            <button disabled={error || created || deleted || fbError} type="button" onClick={handleAddImage}> + </button>
          </div>
        )}
          <div className='posts__facebook-container'>
            <div>
              <label>Facebook Post</label>
              <input checked={isVideo ? false : isFacebookPostChecked} disabled={isVideo ? true : false} onChange={(e) => setIsFacebookPostChecked(e.target.checked)} type='checkbox'/>
            </div>
            <div>
              <label>Access Token</label>
              <input type='text' value={accessToken} onChange={(e) => setAccessToken(e.target.value)}/>
            </div>
          </div>
          <div className='posts__facebook-info'>
              <span>How to get facebook access token?</span>
              <ul>
                <li>Go to: <a href='https://developers.facebook.com/tools/explorer/'>https://developers.facebook.com/tools/explorer/</a></li>
                <li>On the right side select <b>Meta App "kamilfilms"</b>.</li>
                <li>Right under Meta App, in User or Page list, select <b>Page Access Tokens - KamilFilms</b>.</li>
                <li>Make sure you have all required permissions.</li>
                <li>Click on <b>Generate Access Token</b> and copy new token to clipboard.</li>
                <li>Paste new access token here.</li>
              </ul>
          </div>
          <button disabled={error || created || deleted || fbError} onClick={handleSubmit}>Create Post</button>
        </div>
            {created && 
              <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
            }
            {error &&
              <AdminMessagePopup message={"Something went wrong."} status={"error"} />
            }
            {deleted &&
              <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
            }
            {fbError &&
              <AdminMessagePopup message={"Something went wrong with facebook access token."} status={"error"} />
            }
        <div className='posts__list'>
          {postList.map((item, index) => (
            <div key={index}><span>{item}</span><button disabled={error || created || deleted || fbError} value={item} onClick={() => deletePost(item)}><img src={images.xmark}/></button></div>
          ))}
        </div>
      </div>
    );
}

export default PostComponent