import FormData from 'form-data';
import React, { createRef, useEffect, useState } from 'react';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const AwardsComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    let nameRef = createRef();
    const [deleted, setDeleted] = useState(false);
    const [awards, setAwards] = useState([]);

    useEffect(() => {
        axios.get(`/awardsGet`).then((response) => {
         setAwards(response.data.awards);
        })
     }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitAwards = () => {
        setError(false);
        setCreated(false);

        if(nameRef.current.value && selectedFile) {

            const formData = new FormData();
            formData.append('image', selectedFile, selectedFile.name);
            formData.append('name', nameRef.current.value);

            axios.post(`/awardsPush`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setSelectedFile(null);
                    setTimeout(() => {
                        setCreated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        }
    }

    const deleteAwards = (e) => {
        setError(false);
        setDeleted(false);

        axios.get(`/AwardsDelete?name=${e}`, ).then((response) => {
            if(response.data.status === 'ok') {
                setDeleted(true);
                setAwards(response.data.awards);
                setTimeout(() => {
                    setDeleted(false);
                }, 2000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        })
    }

  return (
    <div className='admin__component'>
        <h1>Awards</h1>
      <div className='awards__form'>
        <label>Name</label>
        <input maxLength={128} ref={nameRef} type='text'/>
        <input type='file' accept='image/*' onChange={handleFileChange} />
        <button disabled={error || created || deleted} onClick={submitAwards}>Submit</button>
        {created && 
            <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
        }
        {error &&
            <AdminMessagePopup message={"Something went wrong."} status={"error"} />
        }
        {deleted &&
            <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
        }
      </div> 
      <div className='awards__list'>
        {
          awards.map((x, key) => {
              return(
                  <div key={key}><span>{x}</span><button disabled={error || created || deleted} value={x} onClick={() => deleteAwards(x)}><img src={images.xmark}/></button></div>
              )
          })
        }
      </div>
    </div>
  )
}

export default AwardsComponent