import { easeOut, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './HomeHeader.scss';

const HomeHeader = () => {
    const [isImage1Loaded, setIsImage1Loaded] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false);
    const [isImage3Loaded, setIsImage3Loaded] = useState(false);
    const [isImage4Loaded, setIsImage4Loaded] = useState(false);
    const [isImage5Loaded, setIsImage5Loaded] = useState(false);
    const initialGalleryItems = [images.homeGallery1, images.homeGallery2, images.homeGallery3, images.homeGallery4, images.homeGallery5];
    const [galleryItems, setGalleryItems] = useState(initialGalleryItems);
    const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
    const [leftGalleryIndex, setLeftGalleryIndex] = useState(4);
    const [rightGalleryIndex, setRightGalleryIndex] = useState(1);

    useEffect(() => {
        axios.get('/getHomeHeaderImages').then(async(response) => {
            if(response.data.images_data.length == 5) {
                setGalleryItems([response.data.images_data[0].image_url, response.data.images_data[1].image_url, response.data.images_data[2].image_url, response.data.images_data[3].image_url, response.data.images_data[4].image_url])

                const img1 = new Image();  
                img1.src = response.data.images_data[0].image_url;
                img1.onload = () => setIsImage1Loaded(true);

                const img2 = new Image();  
                img2.src = response.data.images_data[1].image_url;
                img2.onload = () => setIsImage2Loaded(true);

                const img3 = new Image();  
                img3.src = response.data.images_data[2].image_url;
                img3.onload = () => setIsImage3Loaded(true);

                const img4 = new Image();  
                img4.src = response.data.images_data[3].image_url;
                img4.onload = () => setIsImage4Loaded(true);

                const img5 = new Image();  
                img5.src = response.data.images_data[4].image_url;
                img5.onload = () => setIsImage5Loaded(true);
            } else {
                const img1 = new Image();  
                img1.src = images.homeGallery1;
                img1.onload = () => setIsImage1Loaded(true);
        
                const img2 = new Image();  
                img2.src = images.homeGallery2;
                img2.onload = () => setIsImage2Loaded(true);
        
                const img3 = new Image();  
                img3.src = images.homeGallery3;
                img3.onload = () => setIsImage3Loaded(true);
        
                const img4 = new Image();  
                img4.src = images.homeGallery4;
                img4.onload = () => setIsImage4Loaded(true);
        
                const img5 = new Image();  
                img5.src = images.homeGallery5;
                img5.onload = () => setIsImage5Loaded(true);
            }
        })
    }, [])

    useEffect(() => {
        const intervalID = setInterval(() => {
            setActiveGalleryIndex((prevIndex) => (prevIndex + 1) % 5);
            setLeftGalleryIndex((prevIndex) => (prevIndex + 1) % 5);
            setRightGalleryIndex((prevIndex) => (prevIndex + 1) % 5);
        }, 5000);

        return () => {
            clearInterval(intervalID);
        }
    }, [galleryItems, activeGalleryIndex])

    let handleGetYear = () => {
        const year = new Date();
        return year.getFullYear();
    }

    let scrollToTop = () => {
        window.scrollTo({
            top: window.innerHeight - 72,
            behavior: 'smooth'
        });
    };

  return (
    <>
    {(isImage1Loaded && isImage2Loaded && isImage3Loaded && isImage4Loaded && isImage5Loaded) ? (
        <div className='home-header'>
           <div className='home-header__image-container'>
                <div className='home-header__images'>
                    {galleryItems.map((item, index) => (
                        <img key={index} className={`home-header__image ${index == activeGalleryIndex ? 'active' : ''}`} src={item} />
                    ))}
                </div>
                <div className='home-header__content'>
                    <motion.h1 initial={{x: -300, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.5, delay: 1, ease: easeOut}}>KamilFilms</motion.h1>
                    <motion.h2 initial={{x: -300, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.5, delay: 1.2, ease: easeOut}}>RELIVE THE EXPERIENCE</motion.h2>
                    <motion.p initial={{x: -300, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.5, delay: 1.4, ease: easeOut}}>Photography, filmography, and more...</motion.p>
                    <motion.button initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5, delay: 2, ease: easeOut}} onClick={scrollToTop}>DISCOVER MORE</motion.button>
                </div>
           </div>
           <div className='home-header__white-space'>
                <span>@{handleGetYear()} KAMILFILMS</span>
                <span>ALL RIGHTS RESERVED</span>
           </div>
           <motion.div initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} transition={{duration: 1, delay: 2, ease: easeOut}} className='home-header__gallery'>
                {galleryItems.map((item, index) => (
                    <img key={index} className={`home-header__gallery-image ${index == activeGalleryIndex ? 'front' : index == leftGalleryIndex ? 'left' : index == rightGalleryIndex ? 'right' : 'back'}`} src={item} />
                ))}
           </motion.div>
        </div> 
    ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
    )}
        
    </>
  )
}

export default HomeHeader