import React, { useState, useRef, createRef, useEffect } from 'react';
import axios from './../../../axios';
import FormData from 'form-data';
import { AdminMessagePopup } from './index';
import { images } from './../../../Constants';
import './../AdminStyle.scss';

const WorkWithComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    let titleRef = createRef();
    let descRef = createRef();
    let sinceWhenRef = createRef();
    let toWhenRef = createRef();
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [collabs, setCollabs] = useState([]);

    useEffect(() => {
        axios.get(`/workWithGet`).then((response) => {
         setCollabs(response.data.collabs);
        })
     }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const submitWorkWith = () => {
        setError(false);
        setCreated(false);

        if(titleRef.current.value && selectedFile && descRef.current.value) {

            const formData = new FormData();
            formData.append('image', selectedFile, selectedFile.name);
            formData.append('title', titleRef.current.value);
            formData.append('desc', descRef.current.value);
            formData.append('sinceWhen', sinceWhenRef.current.value);
            formData.append('toWhen', toWhenRef.current.value);

            axios.post(`/workWithPush`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setSelectedFile(null);
                    setTimeout(() => {
                        setCreated(false);
                        window.location.reload();
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        }
    }

    const deleteCollab = (e) => {
        setError(false);
        setDeleted(false);

        axios.get(`/workWithDelete?name=${e}`, ).then((response) => {
            if(response.data.status === 'ok') {
                setDeleted(true);
                setCollabs(response.data.collabs);
                setTimeout(() => {
                    setDeleted(false);
                }, 2000);
            } else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        })
    }

  return (
    <div className='admin__component'>
        <h1>Famous people we work with? "About" section.</h1>
        <div className='work-with__form'>
            <label>Title</label>
            <input maxLength={128} ref={titleRef} type='text'/>
            <label>Description</label>
            <textarea ref={descRef} />
            <div className='work-with__dates'>
                <div className='work-with__date'>
                    <label>Since when</label>
                    <select ref={sinceWhenRef} name='sinceWhen'>
                        {(() => {
                            let i = new Date().getFullYear();
                            const options = [];

                            for (i; i >= 1986; i--) {
                              options.push(
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              );
                            }

                            return options;
                        })()}
                    </select>
                </div>
                <div className='work-with__date'>
                    <label>To when</label>
                    <select ref={toWhenRef} name='toWhen'>
                        <option value={'now'}>now</option>
                        {(() => {
                            let i = new Date().getFullYear();
                            const options = [];

                            for (i; i >= 1986; i--) {
                              options.push(
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              );
                            }

                            return options;
                        })()}
                    </select>
                </div>
            </div>
            <label>Image</label>
            <input type='file' accept='image/*' onChange={handleFileChange} />
            <button disabled={error || created || deleted} onClick={submitWorkWith}>Submit</button>
            {created && 
                <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
            }
            {error &&
                <AdminMessagePopup message={"Something went wrong."} status={"error"} />
            }
            {deleted &&
                <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
            }
        </div>
        <div className='work-with__list'>
            {
                collabs.map((x, key) => {
                    return(
                        <div key={key}><span>{x}</span><button disabled={error || created || deleted} value={x} onClick={() => deleteCollab(x)}><img src={images.xmark}/></button></div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default WorkWithComponent