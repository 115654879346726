import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { images } from './../../Constants';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import './ResetPassword.scss';

const ResetAuthenticated = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [accessGranted, setAccessGranted] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState(''); 
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  let { token } = useParams();

  useEffect(() => {
    axios.get(`verifyReset?token=${token}`).then((response) => {
        if(response.data.status !== "authorized") {
            setAccessGranted(false);
            navigate('/reset-password');
        } else {
            setAccessGranted(true);
        }
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  let resetPassword = () => {
    if(login !== '' && password !== '' && repeatPassword !== '' && password === repeatPassword) {
        axios.get(`verifyReset?token=${token}`).then((response) => {
            if(response.data.status === "authorized") {
                setError(false);
                axios.post('/changeAuthenticatedPassword', {login, password}).then((response2) => {
                    if(response2.data.status === "ok") {
                        setError(false);
                        setSuccess(true);
                    } else {
                        setError(true);
                    }
                })
            } else {
                setError(true);
            }
        });
    }
  }

  return (
    <div className='reset-authenticated'>
      {fontLoaded && accessGranted ? (
        <div>
            <img src={images.logo}/>
            <label>Login</label>
            <input value={login} onChange={(e) => setLogin(e.target.value)} type='text'/>
            <label>New Password</label>
            <input value={password} onChange={(e) => setPassword(e.target.value)} type='password'/>
            <label>Confirm Password</label>
            <input value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} type='password'/>
            <button disabled={password === repeatPassword && login !== '' && password !== '' && repeatPassword !== '' ? false : true} onClick={resetPassword}>Submit</button>
            {error && (
                <span style={{color: 'red'}}>Something Went Wrong.</span>
            )}
            {success && (
                <span>New password set successfully. You can go back now by clicking <Link to='/'>here</Link>.</span>
            )}
        </div>
      ) : (
        <AnimatePresence>
          <motion.div className='loading-page' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default ResetAuthenticated