import React, { useEffect, useState } from 'react';
import { NavBar, Footer } from './../index';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import { HomeBest, HomeHeader, HomeHighlighted, HomeWho, HomeWhyUs, HomeClients, HomeOurWork, HomeBlog } from './index';
import './Home.scss';

const Home = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [homeData, setHomeData] = useState({});

  useEffect(() => {
    document.title = "KamilFilms";

    axios.get('/homeGetData').then((response) => {
        if(response.data.status === 'ok') {
            setHomeData(response.data.homeData);
        }
    })
  
    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, []);

  return (
    <>
      {(fontLoaded && Object.keys(homeData).length == 8) ? (
        <div>
          <NavBar />
          <div className='home-container'>
            <HomeHeader />
            <HomeWho data={homeData} />
            <HomeBest data={homeData} />
            <HomeWhyUs data={homeData} />
            <HomeHighlighted />
            <HomeClients />
            <HomeOurWork />
            <HomeBlog />
          </div>
          <Footer />
        </div>
      ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
      )}
    </>
  )
}

export default Home