import React from 'react';
import {images} from './../../Constants';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { useEffect, useState } from 'react';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import axios from './../../axios';
import './Share.scss';

const SharePost = (props) => {
  const [url, setUrl] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    axios.get('/getEnvData').then((response) => {
      setUrl(`${response.data.url}/blog/post/${(props.title).replace(/\s+/g, '%20')}`);
    })
  }, [])

  let copyLink = () => {
    navigator.clipboard.writeText(url);
    setShowMessage(true);
    setTimeout(() => {
        setShowMessage(false);
    }, 3000)
  }

  return (
    <div className='share'>
        <span>Share this here:</span>
        <div className='share__items'>
            <div className='share__item'><WhatsappShareButton url={url} title={`Check out this new post by KamilFilms! ${props.title}:`}><img src={images.whatsappColor} alt='YT'/></WhatsappShareButton></div>
            <div className='share__item'><TwitterShareButton title={`Check out this awesome new post: ${props.title}, by KamilFilms!`} via='KamilFilms' url={url}><img src={images.twitterColor} alt='X'/></TwitterShareButton></div>
            <div className='share__item'><FacebookShareButton url={url} quote={`${props.title}.\n ${props.short_desc}...\n\n By KamilFilms!`} type='website'><img src={images.facebookColor} alt='FB'/></FacebookShareButton></div>
            <div className='share__item'><img src={images.shareLink} alt='SH' onClick={copyLink}/></div>
        </div>
        <AnimatePresence>
            {showMessage && (
                <motion.div className='share__message' initial={{opacity: 0, bottom: '-16px'}} animate={{opacity: 1, bottom: '8px'}} exit={{opacity: 0, bottom: '-16px'}}>Link Copied!</motion.div>
            )}
        </AnimatePresence>
    </div>
  )
}

export default SharePost