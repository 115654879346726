import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { images } from './../../Constants';
import axios from './../../axios';
import './AdminLogin.scss';
import './Footer.scss';

const Footer = () => {
  let inputLogin = useRef();
  let inputPassword = useRef();
  let nameRef = useRef();
  let emailRef = useRef();
  let messageRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [duringLogin, setDuringLogin] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  let social = [
    {id: 1, name: 'Youtube', logo: images.youtube, link: 'https://www.youtube.com/@KamilFilms'},
    {id: 2, name: 'Facebook', logo: images.facebook, link: 'https://www.facebook.com/KamilFilms'},
    {id: 3, name: 'Instagram', logo: images.instagram, link: 'https://www.instagram.com/kamilfilms/'},
    {id: 4, name: 'Twitter', logo: images.twitter, link: 'https://twitter.com/KamilFilms_'},
    {id: 5, name: 'IMDB', logo: images.imdb, link: 'https://www.imdb.com/name/nm4821277/?ref_=fn_al_nm_1'},
    {id: 6, name: 'LinkedIn', logo: images.linkedin, link: 'https://www.linkedin.com/in/kamilkrolak/'}
  ];

  let links = [
    {id: 0, name: 'Pages', content: [
      {id: 1, type: 'page', name: 'Home', link: '/'},
      {id: 2, type: 'page', name: 'Videorgaphy', link: '/filmography'},
      {id: 3, type: 'page', name: 'Photography', link: '/photography'},
      {id: 4, type: 'page', name: 'Blog', link: '/blog'},
      {id: 5, type: 'page', name: 'About', link: '/about'},
      {id: 6, type: 'page', name: 'Contact', link: '/contact'},
      {id: 7, type: 'admin', name: 'Admin Panel', link: ''}
    ]},
    {id: 1, name: 'Videography', content: [
      {id: 1, type: 'video', name: 'Corporate & Business', tag: ''},
      {id: 2, type: 'video', name: 'Events', tag: ''},
      {id: 3, type: 'video', name: 'Art & Culture', tag: ''},
      {id: 4, type: 'video', name: 'Entertainment & Music', tag: ''},
      {id: 5, type: 'video', name: 'Sport & Recreation', tag: ''}
    ]},
    {id: 2, name: 'Photography', content: [
      {id: 1, type: 'photo', name: 'Events', tag: ''},
      {id: 2, type: 'photo', name: 'Art & Culture', tag: ''},
      {id: 3, type: 'photo', name: 'Fashion', tag: ''},
      {id: 4, type: 'photo', name: 'Sport & Recreation', tag: ''},
      {id: 5, type: 'photo', name: 'Behind the Scenes', tag: ''},
    ]}
  ]

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem('adminToken');
    axios.get(`verify?token=${token}`).then((response) => {
        if(response.data.status === "authorized") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
    });
  }, []);

  let activeLoginPanel = () => {
    if(!isAdmin) {
      if(!isActive) {setIsActive(true)} else {setIsActive(false);}
    } else {
      let token = localStorage.getItem('adminToken');
      navigate(`/adminpanel/${token}`);
    }
  }

  let loginAdmin = () => {
    setDuringLogin(true);

    axios.post(`/loginAdmin`, {
      inputLogin: inputLogin.current.value, 
      inputPassword: inputPassword.current.value
    }).then((response) => {
      setDuringLogin(false);

      if(response.data.status === 'ok') {
        localStorage.setItem("adminToken", response.data.token);
        setTimeout(() => {
          window.location.reload(false);
        }, 50);
      } else {
        setLoginFailed(true);
        setTimeout(() => {
          setLoginFailed(false);
        }, 3000)
      }
    })
  }

  let handleInputChange = (inputNumber, event) => {
    const value = event.target.value;

    switch(inputNumber) {
      case 1: setInputName(value); nameRef.current.style.border = 'none';
        break;
      case 2: setInputEmail(value); emailRef.current.style.border = 'none'
        break;
      case 3: setInputMessage(value); messageRef.current.style.border = 'none'
        break;
      default:
        break;
    }
  }

  let handleSend = () => {
    if(inputName === '') {
      nameRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    } 
    if(inputEmail === '') {
      emailRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    } 
    if(inputMessage === '') {
      messageRef.current.style.setProperty('border', '2px solid #D23030', 'important')
    }
    if(inputName !== '' && inputEmail !== '' && inputMessage !== '') {
      axios.post(`/sendmessage`, {name: inputName, email: inputEmail, subject: 'Message without subject', message: inputMessage})
      .then((response) => {
        if(response.data.status === 'ok') {
          setMessageSent(true);
          setTimeout(() => {
            setMessageSent(false);
          }, 3000)
        } else {
          setWrongEmail(true);
          setTimeout(() => {
            setWrongEmail(false);
          }, 3000)
        }
      });
    }
  }

  let getCopyrights = () => {
    const current = new Date();
    const currentYear = current.getFullYear();
    return `@${currentYear} KAMILFILMS | ALL RIGHTS RESERVED`;
  }

  return (
    <div className='footer'>
      <div className='footer__container'>
        <div className='footer__form'>
          <div className='footer__form-head'>
            <span>DO YOU WANT TO CONTACT US?</span>
            <span>Send us a message and we'll respond to you as soon as possible.</span>
          </div>
          <div className='footer__form-form'>
            <div className='footer__form-first-row'>
                <input type='text' ref={nameRef} placeholder='Your Name' value={inputName} className='footer__form-input-name' onChange={(e) => handleInputChange(1, e)}/>
                <input type='text' ref={emailRef} placeholder='Your Email' value={inputEmail} className='footer__form-input-email' onChange={(e) => handleInputChange(2, e)}/>
            </div>
            <textarea placeholder='Your Message' ref={messageRef} value={inputMessage} className='footer__form-input-message' onChange={(e) => handleInputChange(3, e)}/>
            <button onClick={handleSend}>SEND MESSAGE</button>
          </div>
          <motion.div animate={messageSent || wrongEmail ? {  y: 0, opacity: 1 } : {}} initial={{ opacity: 0, y: 10 }} transition={{ ease: 'easeOut', duration: 0.1 }} className='footer__alert'>{wrongEmail ? 'Something Went Wrong' : 'Message Sent'}</motion.div>
        </div>
        <div className='footer__links'>
          <div className='footer__links-kamilfilms'>
            {
              links.map((x) => (
                <div key={x.id} className='footer__links-head'>
                  <span>{x.name}</span>
                  <div className='footer__links-content'>
                    {
                      x.content.map((y) => {
                        if(y.type === 'page') {
                          return(
                            <Link key={y.id} to={y.link} className='footer__links-element'>{y.name}</Link>
                          )
                        } else if(y.type === 'admin') {
                          return(
                            <div key={y.id} onClick={activeLoginPanel} className='footer__links-element'>{y.name}</div>
                          )
                        } else if(y.type === 'video') {
                          return(
                            <Link key={y.id} to={'/filmography'} state={{ actTag: y.name }} className='footer__links-element'>{y.name}</Link>
                          )
                        } else if(y.type === 'photo') {
                          return(
                            <Link key={y.id} to={'/photography'} state={{ actTag: y.name }} className='footer__links-element'>{y.name}</Link>
                          )
                        }
                      })
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <div className='footer__links-external'>
            <div className='footer__links-external-follow'><span>Follow Us</span></div>
            <div className='footer__links-external-elements'>
              {
                social.map((x) => (
                  <motion.a href={x.link} key={x.id} className='contact__info-social-element'>
                    <img src={x.logo} />
                    <span>{x.name}</span>
                  </motion.a>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className='footer__copyrights'>{getCopyrights()}</div>
      <AnimatePresence>
        {
          isActive && (
            <motion.div className='LoginModal' anitial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}>
              <div className='LoginModal__content'>
                <div className='close' onClick={activeLoginPanel}><img src={images.xmark} /></div>
                <div className='form'>
                  <input ref={inputLogin} type='text' placeholder='login' />
                  <input ref={inputPassword} type='text' placeholder='password' />
                  <button onClick={loginAdmin}>Log In</button>
                  {
                    duringLogin && (
                      <ColorRing
                        height="64"
                        width="64"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
                      />
                    )
                  }
                  {
                    loginFailed && (
                      <span>Something Went Wrong</span>
                    )
                  }
                </div>
              </div>
            </motion.div>   
          )
        }
      </AnimatePresence>
    </div>
  )
}

export default Footer