import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from '../../../axios';
import {images} from '../../../Constants';
import { Link, useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { ImageCoop } from './../../index';
import './AboutCoop.scss';

const AboutCoop = (data) => {
    const [coopData, setCoopData] = useState();

    useEffect(() => {
        axios.get('/aboutGetCoop').then((response) => {
            if(response.data.status === 'ok') {
              setCoopData(response.data.data);
            }
        })
    }, [])
    
  return (
    <>
    {coopData && (
        <div className='about-coop'>
        <span>Who do we work with?</span>
        <div className='about-coop__desc'>
            <p>{data.desc}</p>
        </div>
        <div className='about-coop__gallery'>
            <Carousel showThumbs={false} infiniteLoop={true}>
                {coopData.map((item, index) => (
                    <div key={index} className='about-coop__gallery-item'>
                        <div className='about-coop__image'>
                            <ImageCoop src={item.image_url} alt={item.title} isActive={false} />
                        </div>
                        <div className='about-coop__content'>
                            <div className='about-coop__text'>
                                <span>{item.title.toUpperCase()}</span>
                                <p>{item.desc}</p>
                            </div>
                            <div className='about-coop__time'>
                                <b>Partnership</b>
                                <span>{item.since_when} - {item.is_continued == 0 ? item.to_when : 'now'}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
        </div>
    )}
    </>
  )
}

export default AboutCoop