import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { images } from './../../../Constants';
import axios from './../../../axios';
import './AboutCategories.scss';

const AboutCategories = () => {
    const [getData, setGetData] = useState([]);

    useEffect(() => {
        axios.get('/aboutGetData').then((response) => {
            if(response.data.status === 'ok') {
                setGetData(response.data.aboutData);
            }
        })
    }, [])

    let categories = [
        {id: 0, type: 'filmography', src: images.calendar, txt: 'Event Videography & Photography', tag: 'Events'},
        {id: 1, type: 'filmography', src: images.filmCamera, txt: 'Art & Culture', tag: 'Art & Culture'},
        {id: 2, type: 'filmography', src: images.clapperboard, txt: 'Corporate & Commercial Videos', tag: 'Corporate & Business'},
        {id: 3, type: 'filmography', src: images.spockHand, txt: 'Fiction Film', tag: 'Art & Culture'},
        {id: 4, type: 'filmography', src: images.music, txt: 'Music Videos', tag: 'Entertainment & Music'},
        {id: 5, type: 'photography', src: images.paintbrush, txt: 'Art Photography', tag: 'Art & Culture'},
        {id: 6, type: 'photography', src: images.shirt, txt: 'Fashion Photography', tag: 'Fashion'},
        {id: 7, type: 'filmography', src: images.volleyball, txt: 'Sport & Recreation', tag: 'Sport & Recreation'}
    ];

    const list = {
        visible: {
          opacity: 1,
          transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            when: "afterChildren",
          },
        },
      }

      const singleItem = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -100 },
      }

  return (
    <>
    {getData != [] && (
        <div className='about-categories'>
        <span>What we do?</span>
        <div className='about-categories__desc'>
            <p>{getData.what_we_do}</p>
        </div>
        <p>We specialize on multiple fields, such as:</p>
        <motion.div className='about-categories__grid' initial="hidden" whileInView="visible" variants={list}>
            {
                categories.map((item, index) => (
                    <motion.div variants={singleItem} key={index}><Link to={item.type === 'filmography' ? '/filmography' : '/photography'} state={{ actTag: item.tag }} className='about-categories__tile'>
                        <img src={item.src} alt={item.txt} />
                        <span>{item.txt.toUpperCase()}</span>
                    </Link></motion.div>
                ))
            }
        </motion.div>
        </div>
    )}
    </>
    
  )
}

export default AboutCategories