import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './AboutInfo.scss';
import { ImageComponent } from './../../index';

const AboutInfo = (data) => {

  return (
    <div className='about-info'>
        <div className='about-info__kamil-krolak'>
            <div className='about-info__content'>
                <div className='about-info__header'>
                    <h1>KAMIL KROLAK</h1>
                    <span>Founder of KamilFilms</span>
                </div>
                <div className='about-info__text-container'>
                    {data.paragraph1 ? data.paragraph1.split('\n').map((item, index) => (
                        <p key={index}>{item}</p>
                    )) : (<p>{data.paragraph1}</p>)}
                </div>
            </div>
            <div className='about-info__image'>
                <ImageComponent src={images.aboutKamilKrolak} alt={'Kamil Krolak - Founder of KamilFilms'} srccom={images.aboutKamilKrolakCompressed} />
            </div>
        </div>
        <div className='about-info__film'>
            <div className='about-info__content'>
                <div className='about-info__header'>
                    <span>FILM WORK AND ACHIEVEMENTS</span>
                </div>
                <div className='about-info__text-container'>
                    {data.paragraph2 ? data.paragraph2.split('\n').map((item, index) => (
                        <p key={index}>{item}</p>
                    )) : (<p>{data.paragraph2}</p>)}
                </div>
            </div>
            <div className='about-info__image'>
                <ImageComponent src={images.aboutCamera} alt={'Film Work And Achievements'} srccom={images.aboutCameraCompressed} />
            </div>
        </div>
        <div className='about-info__tv'>
            <div className='about-info__content'>
                <div className='about-info__header'>
                    <span>TV WORK</span>
                </div>
                <div className='about-info__text-container'>
                    {data.paragraph3 ? data.paragraph3.split('\n').map((item, index) => (
                        <p key={index}>{item}</p>
                    )) : (<p>{data.paragraph3}</p>)}
                </div>
            </div>
            <div className='about-info__image'>
                <ImageComponent src={images.aboutFilmmaking} alt={'TV Work'} srccom={images.aboutFilmmakingCompressed} />
            </div>
        </div>
        <div className='about-info__quote'>
            <div className='about-info__quote-container'>
                <div className='about-info__quote-wrapper'><img src={images.quote} /></div>
                <div className='about-info__quote-content'>
                    <i>{data.quote}</i>
                    <i>Kamil Krolak</i>
                </div>
                <div className='about-info__quote-wrapper'><img src={images.quote} /></div>
            </div>
        </div>
    </div>
  )
}

export default AboutInfo