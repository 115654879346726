import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../../axios';
import {images} from './../../../Constants';
import './HomeHighlighted.scss';
import { easeOut, motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom';
import { ImageHighlighted } from './../../index';
import Slider from 'react-slick';

const HomeHighlighted = () => {
    const [highlightedData, setHighlightedData] = useState();
    const [widthPage, setWidthPage] = useState(window.innerWidth);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('/homeGetHighlighted').then((response) => {
            if(response.data.status === 'ok') {
                setHighlightedData(response.data.data);
            }
        })

        const width = window.innerWidth;

        if (width < 500) {
          setItemsPerPage(1);
        } else if (width >= 500 && width < 850) {
          setItemsPerPage(2);
        } else if (width >= 850 && width < 1050) {
          setItemsPerPage(3);
        } else if (width >= 1050 && width < 1500) {
          setItemsPerPage(4);
        } else if (width >= 1500) {
          setItemsPerPage(5);
        }
    }, [])

    useEffect(() => {
      const changeWidth = () => {
        setWidthPage(window.innerWidth);
        
        const width = window.innerWidth;

        if (width < 500) {
          setItemsPerPage(1);
        } else if (width >= 500 && width < 850) {
          setItemsPerPage(2);
        } else if (width >= 850 && width < 1050) {
          setItemsPerPage(3);
        } else if (width >= 1050 && width < 1500) {
          setItemsPerPage(4);
        } else if (width >= 1500) {
          setItemsPerPage(5);
        }
      };
      window.addEventListener('resize', changeWidth);
      return () => {
        window.removeEventListener('resize', changeWidth);
      };
    }, []);

    let handleSeeButton = (dataItem) => {
      let check = dataItem.itemData.image_url;
      if(check.startsWith("kamil_films_filmography") && dataItem.itemData.localization) {
        navigate(`/filmography/${dataItem.itemData.title}`);
      } else {
        navigate(`/photography/${dataItem.itemData.title}`);
      }
    }

  return (
    <>
    {highlightedData && (
        <div className='home-highlighted' style={{color: 'black'}}>
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <span className='home-highlighted__head'>HIGHLIGHTED WORK</span>
            <div className='home-highlighted__carousel-wrapper'>
            <Slider {...{
              infinite: true, 
              speed: 500, 
              slidesToScroll: 1, 
              slidesToShow: highlightedData.length < itemsPerPage ? highlightedData.length : itemsPerPage
            }}>
              {highlightedData.map((item, index) => (
                <button key={index} className='home-highlighted__element'>
                  <div className='home-highlighted__image'><ImageHighlighted src={item.itemUrl} alt={item.itemData.title} /></div>
                  <div className='home-highlighted__content-wrapper'>
                    <div className='home-highlighted__button' onClick={() => handleSeeButton(item)}>SEE ALL</div>
                    <div className='home-highlighted__desc'>
                      <h1>{item.itemData.title}</h1>
                      <p>{item.itemData.short_desc}</p>
                    </div>
                  </div>
                </button>
              ))}
            </Slider>
            </div>
        </div> 
    )}
    </>
  )
}

export default HomeHighlighted