import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import { Link, useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';
import { images } from './../../Constants';
import axios from './../../axios';
import { Footer, ImageComponent, ImageGallery, NavBar } from './../index';
import './Filmography.scss';

const Filmography = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [filmographyData, setFilmographyData] = useState([]);
  const [filmographyDataTagged, setFilmographyDataTagged] = useState([]);
  const [activeTag, setActiveTag] = useState('All');
  const [totalItems, setTotalItems] = useState(0);
  const [showItems, setShowItems] = useState(15);
  const [columnNumber, setColumnNumber] = useState(3);
  const [activeImage, setActiveImage] = useState('');
  const [animateImages, setAnimateImages] = useState({y: 0, opacity: 1});
  const location = useLocation();

  useEffect(() => {
    document.title = "KamilFilms";
    axios.get(`/getFilmographyData`).then((response) => {
      setFilmographyData(response.data.data);
      setFilmographyDataTagged(response.data.data);
      setTotalItems(response.data.data.length);
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  useEffect(() => {
    try {
      if(location.state !== '' && location.state !== null) {
        const { actTag } = location.state;
        setActiveTag(actTag);
        setFilmographyDataTagged(filmographyData.filter((item) => item.tags.includes(actTag)));
        setTotalItems(filmographyData.filter((item) => item.tags.includes(actTag)).length);
      }
    } catch (error) {}
  }, [filmographyData])

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000) {
        setColumnNumber(3);
      } else if(window.innerWidth <= 1000 && window.innerWidth > 650) {
        setColumnNumber(2);
      } else if(window.innerWidth <= 650) {
        setColumnNumber(1);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  let categories = [
    {id: 1, name: 'All', image: images.categoriesImageAll, imagecom: images.categoriesImageAllCompressed},
    {id: 2, name: 'Corporate & Business', image: images.categoriesImageCorporate, imagecom: images.categoriesImageCorporateCompressed},
    {id: 3, name: 'Events', image: images.categoriesImageEvents, imagecom: images.categoriesImageEventsCompressed},
    {id: 4, name: 'Entertainment & Music', image: images.categoriesImageMusic, imagecom: images.categoriesImageMusicCompressed},
    {id: 5, name: 'Art & Culture', image: images.categoriesImageDance, imagecom: images.categoriesImageDanceCompressed},
    {id: 8, name: 'Sport & Recreation', image: images.categoriesImageSport, imagecom: images.categoriesImageSportCompressed}
  ];

  let handleCategoryChange = (name) => {
    setActiveTag(name);
    setAnimateImages([{y: 50, opacity: 0}]);

    setTimeout(() => {
      setAnimateImages([{y: 0, opacity: 1}]);
      setShowItems(15);
      if(name === 'All') {
        setFilmographyDataTagged(filmographyData);
        setTotalItems(filmographyData.length);
      } else {
        setFilmographyDataTagged(filmographyData.filter((item) => item.tags.includes(name)));
        setTotalItems(filmographyData.filter((item) => item.tags.includes(name)).length);
      }
    }, 400);
  }

  let handleExpandClick = () => {
    if(showItems + 9 <= totalItems) {
      setShowItems((prevShowItems) => prevShowItems + 9);
    } else {
      setShowItems(totalItems);
    } 
  }

  let handleActiveImage = (e) => {
    if(activeImage === e) {
      setActiveImage('')
    } else {
      setActiveImage(e)
    }
  }

  const categoryAnimation = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.5
      }},
    hidden: { opacity: 0 }
  }

  const categoryAnimationChild = {
    visible: { opacity: 1, },
    hidden: { opacity: 0 }
  }

  return (
    <div className='filmography__component'>
      {fontLoaded ? (
        <div>
          <NavBar/>
          <div className='filmography'>
            <motion.div initial={{opacity: 0, y: -50}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5, delay: 1, ease: 'easeOut'}} className='filmography__headline'><span>OUR FILMOGRAPHY</span></motion.div>
            <motion.div className='filmography__categories' initial="hidden" animate="visible" variants={categoryAnimation}>
              {
                categories.map((item, index) => (
                  <motion.div key={index} variants={categoryAnimationChild} className={`filmography__category-item ${activeTag === item.name ? 'active' : ''}`} onClick={(name) => handleCategoryChange(item.name)}>
                    <div className='filmography__image-wrapper'><ImageComponent src={item.image} alt={`Category ${item.name}`} srccom={item.imagecom} /></div>
                    <span>{item.name}</span>
                  </motion.div>
                ))
              }
            </motion.div>
            <motion.div initial={{y: 100, opacity: 0}} animate={animateImages} transition={{duration: 0.6, delay: 1, ease: 'easeInOut'}} className='filmography__content'>
              <div className='filmography__content-column'>
                {
                  filmographyDataTagged.slice(0, showItems).map((item, index) => index % columnNumber === 0 && (
                    <div key={index} className={`filmography__content-item ${activeImage === item.title ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.title)}>
                      <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.title ? true : false} />
                      <div className='filmography__content-item-active'>
                        <div className='filmography__content-item-active-text'>
                          <span className='filmography__content-item-active-title'>{item.title}</span>
                          <span>{item.short_desc}</span>
                        </div>
                        <Link to={`/filmography/${item.title}`}><button className='filmography__content-item-active-seevideo'>SEE VIDEO</button></Link>
                      </div>
                      <div className='filmography__content-item-tags'>
                        <span>Categories</span>
                        <div className='filmography__content-item-taglist'>
                          {
                            item.tags.map((it, index) => (
                              <div key={index} className='filmography__content-item-tag'>{it}</div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
              {columnNumber >= 2 && (
                <div className='filmography__content-column'>
                  {
                    filmographyDataTagged.slice(0, showItems).map((item, index) => index % columnNumber === 1 && (
                      <div key={index} className={`filmography__content-item ${activeImage === item.title ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.title)}>
                        <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.title ? true : false} />
                        <div className='filmography__content-item-active'>
                          <div className='filmography__content-item-active-text'>
                            <span className='filmography__content-item-active-title'>{item.title}</span>
                            <span>{item.short_desc}</span>
                          </div>
                          <Link to={`/filmography/${item.title}`}><button className='filmography__content-item-active-seevideo'>SEE VIDEO</button></Link>
                        </div>
                        <div className='filmography__content-item-tags'>
                          <span>Categories</span>
                          <div className='filmography__content-item-taglist'>
                            {
                              item.tags.map((it, index) => (
                                <div key={index} className='filmography__content-item-tag'>{it}</div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )}
              {columnNumber == 3 && (
                <div className='filmography__content-column'>
                  {
                    filmographyDataTagged.slice(0, showItems).map((item, index) => index % columnNumber === 2 && (
                      <div key={index} className={`filmography__content-item ${activeImage === item.title ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.title)}>
                        <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.title ? true : false} />
                        <div className='filmography__content-item-active'>
                          <div className='filmography__content-item-active-text'>
                            <span className='filmography__content-item-active-title'>{item.title}</span>
                            <span>{item.short_desc}</span>
                          </div>
                          <Link to={`/filmography/${item.title}`}><button className='filmography__content-item-active-seevideo'>SEE VIDEO</button></Link>
                        </div>
                        <div className='filmography__content-item-tags'>
                          <span>Categories</span>
                          <div className='filmography__content-item-taglist'>
                            {
                              item.tags.map((it, index) => (
                                <div key={index} className='filmography__content-item-tag'>{it}</div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )}
            </motion.div>
            {showItems < totalItems && (
              <div className='filmography__see-more'>
                <button onClick={handleExpandClick}>SEE MORE<img src={images.arrowDown} alt='arrow_down'/></button>
              </div>
            )}
          </div>
          <Footer/>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div className='loading-page' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default Filmography