import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { images } from './../../Constants';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import './ResetPassword.scss';

const ResetPassword = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    document.title = "KamilFilms";
    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  let sendRequest = () => {
    if(emailInput !== '') {
        axios.post('/resetPassword', {emailInput}).then((response) => {
            if(response.data.status === 'ok') {
                setError(false);
            } else {
                setError(true);
            }
        })
    }
  }

  return (
    <div className='reset-password'>
      {fontLoaded ? (
        <div>
          <img src={images.logo}/>
          <label>Login</label>
          <input value={emailInput} onChange={(e) => setEmailInput(e.target.value)} type='text'/>
          <button disabled={emailInput === '' ? true : false} onClick={sendRequest}>SEND</button>
          {error && (
            <span>Something wrong.</span>
          )}
        </div>
      ) : (
        <AnimatePresence>
          <motion.div className='loading-page' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default ResetPassword