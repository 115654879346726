import React, { useEffect, useState, useRef } from 'react';
import {NavBar, Footer} from '../index';
import { AboutHeader, AboutCategories, AboutCoop, AboutAwards, AboutInfo } from './index';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import {images} from './../../Constants';
import './About.scss';
import { useLocation } from 'react-router';

const About = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [aboutData, setAboutData] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [coopData, setCoopData] = useState([]);
  const [awardsData, setAwardsData] = useState([]);

  useEffect(() => {
    document.title = "KamilFilms";
    
    const img = new Image();  
    img.src = images.aboutMainImage;

    img.onload = () => {
      setIsImageLoaded(true);
    };

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });

  }, [])

  useEffect(() => {
    axios.get('/aboutGetData').then((response) => {
        if(response.data.status === 'ok') {
            setAboutData(response.data.aboutData);
        }
    })

    axios.get('/aboutGetCoop').then((response) => {
      if(response.data.status === 'ok') {
        setCoopData(response.data.data);
      }
    })

    axios.get('/aboutGetAwards').then((response) => {
      if(response.data.status === 'ok') {
        setAwardsData(response.data.data);
      }
    })
  }, [])

  return (
    <div>
      {(fontLoaded && aboutData != [] && isImageLoaded && coopData != [] && awardsData != []) ? (
        <div>
          <NavBar/>
          <div className='about__container'>
            <AboutHeader src={images.aboutMainImage} />
            <AboutCategories desc={aboutData.what_we_do} />
            <AboutCoop data={coopData} desc={aboutData.work_with} />
            <AboutAwards data={awardsData} desc={aboutData.awards} />
            <AboutInfo paragraph1={aboutData.kamil_krolak} paragraph2={aboutData.film_work} paragraph3={aboutData.tv_work} quote={aboutData.quote} />
          </div>
          <Footer/>
        </div>
      ) : (
        <div className='loading-page'>
          <ColorRing
            height="128"
            width="128"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
          />
        </div>
      )}
    </div>
  )
};

export default About;