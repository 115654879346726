import React, { useEffect, useState } from 'react';
import { NavBar, Footer, ImageGallery, ImageComponent } from './../index';
import { Link, useParams } from 'react-router-dom';
import { images } from './../../Constants';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import axios from './../../axios';
import './NotExistingPage.scss';

const NotExistingPage = () => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    document.title = "KamilFilms";
    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  return (
    <div className='notexist__component'>
      {fontLoaded ? (
        <div className='notexist'>
          <img src={images.logo}/>
          <div className='notexist__content'>
            <span>This page does not exist.</span>
            <Link to='/'><button>GO TO HOMEPAGE</button></Link>
          </div>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div className='loading-page' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default NotExistingPage