import React, { useEffect, useState } from 'react';
import axios from './../../../axios';
import './../AdminStyle.scss';
import { AdminMessagePopup } from './index';

const AboutNumbersComponent = () => {
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [projectNumber, setProjectNumber] = useState(null);
    const [awardsNumber, setAwardsNumber] = useState(null);

    useEffect(() => {
        axios.get(`/getStatsData`).then((response) => {
            if(response.data.status === 'ok') {
                setProjectNumber(response.data.projectsNumber);
                setAwardsNumber(response.data.awardsNumber);
            }
        })
     }, []);

    const submitStats = () => {
        setError(false);
        setCreated(false);

            axios.post(`/pushStatsData`, {projectNumber, awardsNumber}).then((response) => {
                if(response.data.status === 'ok') {
                    setCreated(true);
                    setProjectNumber(response.data.pNumber);
                    setAwardsNumber(response.data.aNumber);
                    setTimeout(() => {
                        setCreated(false);
                    }, 1500);
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 2000);
                }
            });
        
    }

  return (
    <div className='admin__component'>
        <h1>About Statistics</h1>
        <div className='awards__form'>
        <label>Number of Projects</label>
        <input type='number' value={projectNumber} onChange={(e) => setProjectNumber(e.target.value)}/>
        <label>Number of Awards</label>
        <input type='number' value={awardsNumber} onChange={(e) => setAwardsNumber(e.target.value)}/>
        <button disabled={error || created || deleted} onClick={submitStats}>Submit</button>
        {created && 
            <AdminMessagePopup message={"Object created successfully!"} status={"success"} />
        }
        {error &&
            <AdminMessagePopup message={"Something went wrong."} status={"error"} />
        }
        {deleted &&
            <AdminMessagePopup message={"Object deleted successfully!"} status={"success"} />
        }
      </div> 
    </div>
  )
}

export default AboutNumbersComponent