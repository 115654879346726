import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { About, Admin, Blog, BlogPost, Contact, Filmography, FilmographyItem, Home, NotExistingPage, Photography, PhotographyItem, ResetAuthenticated, ResetPassword } from './Components/index';
import axios from './axios';

function App(props) {
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    let token = localStorage.getItem('adminToken');
    axios.get(`verify?token=${token}`).then((response) => {
        if(response.data.status === "authorized") {
            setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
    });
}, [])

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/filmography" element={<Filmography />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:page" element={<Blog />} />
        <Route path="/blog/post/:name" element={<BlogPost />} />
        <Route path="/adminpanel/:token" element={<Admin />} />
        <Route path="/filmography/:name" element={<FilmographyItem />} />
        <Route path="/photography/:name" element={<PhotographyItem />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetAuthenticated />} />

        <Route path="*" element={<NotExistingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
