import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavBar, Footer, Share, ImageGallery } from './../index';
import { Link, useParams } from 'react-router-dom';
import { images } from './../../Constants';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from './../../axios';
import './PhotographyItem.scss';

const PhotographyItem = () => {
    const [fontLoaded, setFontLoaded] = useState(false);
    const [gotData, setGotData] = useState(false);
    const [name, setName] = useState(useParams().name);
    const [data, setData] = useState([]);
    const [webUrl, setWebUrl] = useState('');
    const [photographyData, setPhotographyData] = useState([]);
    const [columnNumber, setColumnNumber] = useState(3);
    const [totalItems, setTotalItems] = useState(0);
    const [showItems, setShowItems] = useState(15);
    const [activeImage, setActiveImage] = useState(null);
    const [activeModalImage, setActiveModalImage] = useState(0);
    const [animateImages, setAnimateImages] = useState({y: 0, opacity: 1});
    const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get(`/getPhotographyItemData?name=${name}`).then((response) => {
      if(response.data.status === 'ok') {
        document.title = response.data.title;
        setWebUrl(response.data.url);
        setData(response.data);
        setPhotographyData(response.data.images_data);
        setTotalItems(response.data.images_data.length);
        setGotData(true);
      }
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 1000) {
        setColumnNumber(3);
      } else if(window.innerWidth <= 1000 && window.innerWidth > 650) {
        setColumnNumber(2);
      } else if(window.innerWidth <= 650) {
        setColumnNumber(1);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  let handleExpandClick = () => {
    if(showItems + 9 <= totalItems) {
      setShowItems((prevShowItems) => prevShowItems + 9);
    } else {
      setShowItems(totalItems);
    } 
  }

  let parseDate = () => {
    if(data.created_at !== '') {
        const date = data.created_at;
        const transformedDate = date.split('T')[0];
        const partsDate = transformedDate.split('-');
        const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
        return reversedDate;  
    } else {
        return data.created_at
    }
  }

  let handleActiveImage = (e) => {
    if(activeImage == e) {
      setActiveImage(null)
    } else {
      setActiveImage(e)
    }
  }

  let openModal = (e) => {
    setActiveModalImage(e);
    setShowModal(true);
  }

  let closeModal = () => {
    setShowModal(false);
  }

  return (
    <div className='photography-item__component'>
      {fontLoaded && gotData && webUrl !== '' ? (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Helmet>
            <meta property='og:type' content="article" />
            <meta property="og:url" content={`${webUrl}/photography/${name}`} />
            <meta property='og:title' content={data.title} />
            <meta property='og:description' content={data.short_desc} />
            <meta property='og:image' content={data.image_url} />

            <meta name="twitter:title" content={data.title} />
            <meta name="twitter:description" content={data.short_desc} />
            <meta name="twitter:image" content={data.image_url} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>

          <NavBar/>
          <motion.div className='photography-item' anitial={{opacity: 0}} animate={{opacity: 1}} transition={{ ease: 'easeOut', duration: 0.5}}>
            <Link to={`/photography`}><button className='photography-item__go-back'><img src={images.arrowLeft} alt='Arrow'/><span>GO BACK</span></button></Link>
            <div className='photography-item__date'>{parseDate()}</div>
            <div className='photography-item__title'>{data.title}</div>
            <div className='photography-item__desc'>
                {data.long_desc.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
            <div className='photography-item__options'>
                <Share title={data.title} short_desc={data.short_desc} tags={data.tags} image={data.image_url} typ={'photography'} />
                <div className='photography-item__categories top'>
                    <span>Categories</span>
                    <div className='photography-item__categories-list'>
                        {
                            data.tags.map((item, index) => (
                                <div key={index}>{item.toUpperCase()}</div>
                            ))
                        }
                    </div>
                </div>
                <div className='photography-item__author'>
                    <img src={images.camera} alt='localization'/><span>{data.author}</span>
                </div>
            </div>
          </motion.div>
            <motion.div initial={{y: 100, opacity: 0}} animate={animateImages} transition={{duration: 0.6, delay: 1, ease: 'easeInOut'}} className='photography__content'>
              <div className='photography__content-column'>
                {
                  photographyData.slice(0, showItems).map((item, index) => index % columnNumber === 0 && (
                    <div key={index} className={`photography__content-item ${activeImage === item.id ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.id)}>
                      <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.id ? true : false} />
                      <div className='photography__content-item-active'>
                        <button onClick={() => openModal(index)} className='photography__content-item-active-seevideo'>SEE PHOTO</button>
                      </div>
                    </div>
                  ))
                }
              </div>
              {columnNumber >= 2 && (
                <div className='photography__content-column'>
                  {
                    photographyData.slice(0, showItems).map((item, index) => index % columnNumber === 1 && (
                        <div key={index} className={`photography__content-item ${activeImage === item.id ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.id)}>
                          <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.id ? true : false} />
                          <div className='photography__content-item-active'>
                            <button onClick={() => openModal(index)} className='photography__content-item-active-seevideo'>SEE PHOTO</button>
                          </div>
                        </div>
                      ))
                  }
                </div>
              )}
              {columnNumber == 3 && (
                <div className='photography__content-column'>
                  {
                    photographyData.slice(0, showItems).map((item, index) => index % columnNumber === 2 && (
                        <div key={index} className={`photography__content-item ${activeImage === item.id ? 'active' : 'not'}`} onClick={() => handleActiveImage(item.id)}>
                          <ImageGallery src={item.image_url} alt={item.title} isActive={activeImage === item.id ? true : false} />
                          <div className='photography__content-item-active'>
                            <button onClick={() => openModal(index)} className='photography__content-item-active-seevideo'>SEE PHOTO</button>
                          </div>
                        </div>
                      ))
                  }
                </div>
              )}
            </motion.div>
            {showItems < totalItems && (
              <div className='photography__see-more'>
                <button onClick={handleExpandClick}>SEE MORE<img src={images.arrowDown} alt='arrow_down'/></button>
              </div>
            )}
            <div className='photography-item__categories bottom'>
                <span>Categories</span>
                <div className='photography-item__categories-list'>
                    {
                        data.tags.map((item, index) => (
                            <div key={index}>{item.toUpperCase()}</div>
                        ))
                    }
                </div>
            </div>
          <Footer/>
          <AnimatePresence>
          {showModal && (
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.25}} className='modal-image'>
                <div className='modal-image__header'>
                    <img src={images.logo} alt='KamilFilms Logo' />
                    <button onClick={() => closeModal()}><img src={images.xmark} alt='close' /></button>
                </div>
                <Carousel emulateTouch selectedItem={activeModalImage} infiniteLoop className='modal-image__content'>
                    {data.images_data.map((item, index) => (
                            <div className='modal-image__item'><img src={item.image_url} /></div>
                        )
                    )}
                </Carousel>
            </motion.div>
          )}
          </AnimatePresence>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='loading-page'>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
        
      )}
    </div>
  )
}

export default PhotographyItem