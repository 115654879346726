import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavBar, Footer, Share } from './../index';
import { Link, useParams } from 'react-router-dom';
import { images } from './../../Constants';
import { AnimatePresence, delay, easeOut, motion } from 'framer-motion';
import WebFont from 'webfontloader';
import { ColorRing } from 'react-loader-spinner';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';
import axios from './../../axios';
import './FilmographyItem.scss';

const FilmographyItem = () => {
    const [fontLoaded, setFontLoaded] = useState(false);
    const [gotData, setGotData] = useState(false);
    const [name, setName] = useState(useParams().name);
    const [data, setData] = useState([]);
    const [webUrl, setWebUrl] = useState('');

  useEffect(() => {
    axios.get(`/getFilmographyItemData?name=${name}`).then((response) => {
      if(response.data.status === 'ok') {
        document.title = response.data.data.title;
        setWebUrl(response.data.url);
        setData(response.data.data);
        setGotData(true);
      }
    });

    WebFont.load({
      custom: {
        families: ['federo', 'montserrat', 'montserrat-semibold', 'montserrat-bold', 'montserrat-light'],
        urls: ["./../../index.css"],
      },
      active: () => {
        setFontLoaded(true);
      },
    });
  }, [])

  let parseDate = () => {
    if(data.created_at !== '') {
        const date = data.created_at;
        const transformedDate = date.split('T')[0];
        const partsDate = transformedDate.split('-');
        const reversedDate = `${partsDate[2]}.${partsDate[1]}.${partsDate[0]}`;
        return reversedDate;  
    } else {
        return data.created_at
    }
  }

  let isYoutube = () => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    if(pattern.test(data.video_url)) {
        return true;
    } else return false;
  }

  let isVimeo = () => {
    const pattern = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    if(pattern.test(data.video_url)) {
        return true;
    } else return false;
  }

  let getYoutubeEmbed = () => {
    const lastIndex = data.video_url.lastIndexOf('/');
    let lastPart = '';
    if(lastIndex > -1 && lastIndex < data.video_url.length - 1) {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 1];
    } else {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 2];
    }
    const split = lastPart.split('v=');
    return split.pop();
  }

  let getVimeoId = () => {
    const lastIndex = data.video_url.lastIndexOf('/');
    let lastPart = '';
    if (lastIndex > -1 && lastIndex < data.video_url.length - 1) {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 1];
    } else {
        const parts = data.video_url.split('/');
        lastPart = parts[parts.length - 2];
    }
    
    const indexOfQuestionMark = lastPart.indexOf('?');
    const videoId = indexOfQuestionMark !== -1 ? lastPart.substring(0, indexOfQuestionMark) : lastPart;
    
    const split = videoId.split('v=');
    return split.pop();
  }

  return (
    <div className='filmography-item__component'>
      {fontLoaded && gotData && webUrl !== '' ? (
        <div>
          <Helmet>
            <meta property='og:type' content="article" />
            <meta property="og:url" content={`${webUrl}/filmography/${name}`} />
            <meta property='og:title' content={data.title} />
            <meta property='og:description' content={data.short_desc} />
            <meta property='og:image' content={data.image_url} />

            <meta name="twitter:title" content={data.title} />
            <meta name="twitter:description" content={data.short_desc} />
            <meta name="twitter:image" content={data.image_url} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>

          <NavBar/>
          <motion.div className='filmography-item' anitial={{opacity: 0}} animate={{opacity: 1}} transition={{ ease: 'easeOut', duration: 0.5}}>
            <Link to={`/filmography`}><button className='filmography-item__go-back'><img src={images.arrowLeft} alt='Arrow'/><span>GO BACK</span></button></Link>
            <div className='filmography-item__date'>{parseDate()}</div>
            <div className='filmography-item__title'>{data.title}</div>
            {isYoutube() ? (
              <div className='filmography-item__video-player youtube'>
                  <iframe
                    src={`https://youtube.com/embed/${getYoutubeEmbed()}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder='0'
                    title={data.title}
                  />
              </div>
            ) : isVimeo() ? (
              <div className='filmography-item__video-player vimeo'><Vimeo video={getVimeoId()}/></div>
            ) : (
              <div className='filmography-item__video-player facebook'><ReactPlayer width='fit-content' height='100%' url={data.video_url}/></div>
            )}
            <div className='filmography-item__data-row'>
                <div className='filmography-item__localization'>
                    <img src={images.localization} alt='localization'/><span>{data.localization}</span>
                </div>
                <div className='filmography-item__author'>
                    <img src={images.camera} alt='localization'/><span>{data.author}</span>
                </div>
            </div>
            <div className='filmography-item__desc'>
              {data.long_desc.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
            <div className='filmography-item__options'>
                <Share title={data.title} short_desc={data.short_desc} tags={data.tags} image={data.image_url} type={'filmography'} />
                <div className='filmography-item__categories'>
                    <span>Categories</span>
                    <div className='filmography-item__categories-list'>
                        {
                            data.tags.map((item, index) => (
                                <div key={index}>{item.toUpperCase()}</div>
                            ))
                        }
                    </div>
                </div>
            </div>
          </motion.div>
          <Footer/>
        </div>
      ) : (
        <AnimatePresence>
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} className='loading-page'>
            <ColorRing
              height="128"
              width="128"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#747fe5', '#747fe5', '#747fe5', '#747fe5', '#747fe5']}
            />
          </motion.div>
        </AnimatePresence>
        
      )}
    </div>
  )
}

export default FilmographyItem