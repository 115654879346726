import React, { useState, useRef, createRef, useEffect } from 'react';
import axios from './../../../axios';
import FormData from 'form-data';
import { AdminMessagePopup } from './index';
import { images } from './../../../Constants';
import './../AdminStyle.scss';

const HighlightedComponent = () => {
    const [highlightedVideos, setHighlightedVideos] = useState([]);
    const [highlightedPhotos, setHighlightedPhotos] = useState([]);

    const fetchHighlightedData = () => {
        axios.get(`/getHighlighted`).then((response) => {
            setHighlightedVideos(response.data.videos);
            setHighlightedPhotos(response.data.photos);
        })
    }

    useEffect(() => {
        fetchHighlightedData();
    }, []);

    const submitHighlighted = (x, e) => {
        axios.post('/submitHighlighted', {value: x, type: e}).then((response) => {
            fetchHighlightedData();
        })
    }

    const deleteHighlighted = (x, e) => {
        axios.post('/deleteHighlighted', {value: x, type: e}).then((response) => {
            fetchHighlightedData();
        })
    }

  return (
    <div className='admin__component'>
        <h1>Highlighted Work</h1>
      <div className='highlighted__list'>
        <div className='highlighted__videos'>
            <span>Films</span>
            {
                highlightedVideos.map((x, index) => (
                    <div className={`highlighted__element ${x.is_highlighted ? 'off' : 'on'}`} key={index}>{x.title}<button value={x.title} name='video' onClick={x.is_highlighted ? (e) => deleteHighlighted(x.title, e.target.name) : (e) => submitHighlighted(x.title, e.target.name)}>{x.is_highlighted ? 'x' : '+'}</button></div>
                ))
            }
        </div>
        <div className='highlighted__photos'>
            <span>Photos</span>
            {
                highlightedPhotos.map((x, index) => (
                    <div className={`highlighted__element ${x.is_highlighted ? 'off' : 'on'}`} key={index}>{x.title}<button value={x.title} name='photo' onClick={x.is_highlighted ? (e) => deleteHighlighted(x.title, e.target.name) : (e) => submitHighlighted(x.title, e.target.name)}>{x.is_highlighted ? 'x' : '+'}</button></div>
                ))
            }
        </div>
      </div>
    </div>
  )
}

export default HighlightedComponent