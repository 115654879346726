import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from '../../../axios';
import './AboutAwards.scss';

const AboutAwards = (data) => {
    const [awardsData, setAwardsData] = useState();

    useEffect(() => {
        axios.get('/aboutGetAwards').then((response) => {
            if(response.data.status === 'ok') {
              setAwardsData(response.data.data);
            }
          })
    }, [])

    let handleChunkData = () => {
        const chunkSize = 5;
        const chunkedData = [];

        for (let i = 0; i < awardsData.length; i += chunkSize) {
          chunkedData.push(awardsData.slice(i, i + chunkSize));
        }

        return chunkedData;
    }

  return (
    <>
    {awardsData && (
        <div className='about-awards'>
        <span>Our achievements</span>
        <div className='about-awards__desc'>
            <p>{data.desc}</p>
        </div>
        <div className='about-awards__list'>
            {awardsData ? awardsData.map((item, index) => (
                <div key={index} className='about-awards__item'>
                    <img src={item.image_url} />
                </div>
            )) : 'Loading...'}
        </div>
        <div className='about-awards__gallery'>
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} showIndicators={false} showArrows={false} showStatus={false}>
                {handleChunkData().map((chunk, index) => (
                    <div key={index} className='about-awards__gallery-item'>
                        {chunk.map((item, idx) => (
                            <div  key={idx}><img src={item.image_url}/></div>
                        ))}
                    </div>
                ))}
            </Carousel>
        </div>
        </div>
    )}
    </>
    
  )
}

export default AboutAwards